.lightboxcover {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.81);
  top: 0px;
  left: 0px;
  z-index: 9999;
  .lightbox {
    width: 630px;
    height: 450px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    margin-left: -315px;
    top: 60px;
    padding: 30px;
    overflow: hidden;
    z-index: 9999;
    img {
      max-width: 100%;
    }
  }
}
.x-button {
  color: #fff;
  font-size: 2rem;
  font-weight: 100;
  position: absolute;
  cursor: pointer;
  right: 2rem;
  top: 0;
  svg {
    font-size: 2rem;
  }
  @media (min-width: 300px) and (max-width: 450px){
    font-size: 1rem;
    right: 1rem;
  }
}
.next-button, .prev-button {
  margin: 0 auto;
  top: 50%;
  transform: translate(0%, -50%);
}
.next-button {
  color: #fff;
  font-size: 5rem;
  font-weight: 100;
  position: absolute;
  cursor: pointer;
  right: 1.5rem;
  svg {
    font-size: 5rem;
    @media (min-width: 300px) and (max-width: 450px){
      font-size: 3rem;
    }
  }
  @media (min-width: 300px) and (max-width: 450px){
    font-size: 1rem;
    right: 0.5rem;
    top: 60%;
    transform: translate(0%, -100%);
  }
}
.prev-button {
  color: #fff;
  font-size: 5rem;
  font-weight: 100;
  position: absolute;
  cursor: pointer;
  left: 1.5rem;
  svg {
    font-size: 5rem;
    @media (min-width: 300px) and (max-width: 450px){
      font-size: 3rem;
    }
  }
  @media (min-width: 300px) and (max-width: 450px){
    font-size: 1rem;
    top: 60%;
    transform: translate(0%, -100%);
  }
}