#form-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 79px;
  margin-bottom: 65px;
  @media screen and (max-width: 670px) {
    flex-direction: column;
  }
  .step {
    display: flex;
    align-items: center;
    margin-right:auto;
    padding: 10px 40px;
    &:last-child {
    }
    .count {
      width: 50px;
      height: 50px;
      background-color: #d7d9dd;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }

    p {
      color: #8f96a3;
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 3.6px;
      margin-bottom: 0px;
      margin-top: 0px;
      margin-left: 28px;
    }

    &.current {
      .count {
        background-color: #5ca0e6;
      }

      p {
        color: #5ca0e6;
      }
    }
  }
}

#form {
  .container {
    width: 1300px;
  }
  #warranty-form {
    .form-fields {
      display: flex;
      flex-wrap: wrap;
      .input-holder {
        position: relative;
        flex: 1 0 45%;
        &:nth-child(odd) {
          margin-right: 31px;
        }

        i {
          position: absolute;
          top: 14px;
          left: -2px;
          z-index: 9;
          color: #96d3ff;
          font-size: 24px;
          font-weight: 400;
          letter-spacing: -0.6px;
        }

        svg {
          fill: #96d3ff;
          height: 24px;
          position: absolute;
          top: 14px;
          left: 0px;
          z-index: 9;
        }

        .error_message {
          // display: none;
          color: #d35e5e;
          margin-bottom: 9px;
          width: 100%;
          float: left;
          &.show {
            display: initial;
          }
        }
      }
      input, select {
        margin-bottom: 34px;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: solid 2px #e3e9f6;
        opacity: 0.6;
        color: #384660;
        font-size: 22px;
        font-weight: 400;
        letter-spacing: -0.22px;
        padding-left: 53px;
        padding-bottom: 19px;
        outline-width: 0;
        &.no-icon {
          padding-left: 0px;
        }
      }

      select {
        height: 75px;
        background: transparent;
        text-indent: 43px;
        border-radius: 0px;
        margin-top: -10px;
        &.no-icon {
          text-indent: 0px;
        }
      }
    }

    .form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 236px;
      button {
        width: 399px;
        border: 0px;
        box-shadow: 0 20px 57px rgba(218, 137, 36, 0.21);
        border-radius: 10px;
        background-color: #f8901d;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%);
        color: #ffffff;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 4px;
        padding: 35px 20px;
      }
    }
  }
}
.upload-file {
	display: flex;
    justify-content: center;
}

#file-upload {
  border-radius: 10px;
  border: 2px dashed rgba(1, 1, 1, .1);
  background-color: rgba(215, 217, 221, .1);
  text-align: center;
  > div {
    padding: 20px;
  }
  
  h3 {
    color: #406183;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.12px;
  }

  p {
    opacity: 0.7;
    color: #406183;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.09px;
  }

  .button {
    box-shadow: 0 20px 57px rgba(61, 92, 124, 0.14);
    border-radius: 10px;
    background-color: #86a0ba;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%);
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    border: 0px;
    padding: 10px;
    cursor: pointer;
  }
}

#warrantyUploadpreview {
  .dz-preview.dz-image-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .dz-details {
      width: 65px;
      overflow: hidden;
      border-radius: 10px;
    }
    .dz-filename {
      margin-right: auto;
      margin-left: 10px;
    }

    .remove {
      cursor: pointer;
    }
  }
}

#uploadpreview {
    display: flex;
    flex-direction: column;
    .dz-preview {
        border: solid 3px #5ca0e6;
        padding: 10px;
        border-radius: 20px;
        overflow: hidden;
        margin-right: 10px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .dz-details {
          width: 40px;
        }
        
        span {
            &.remove {
                font-weight: 900;
                cursor: pointer;
                margin-left: auto;
                margin-right: 20px;
            }
        }

        .dz-filename {
            margin-left: 20px;
        }
    }
}

.dz-remove {
  margin-left: auto;
}

.fl {
    float: left;
}

.no-cursor {
    cursor: default !important;
}

.issues {
  .left {
    width: 500px;
  }

  .right {
    flex: 1;
    margin-left: 20px;
    .input-holder {
      margin-right: 0px !important;
      label {
        margin-bottom: 10px;
      }
      textarea {
        border-radius: 10px;
        border: 2px solid rgba(14, 18, 32, 0.15);
        font-size: 17px;
        // background-color: #dddddd;
      }
    }
  }
}

.next-btn {
  cursor: pointer;
  &:focus {outline:0;}
	background : #204367 !important;
  color:white !important;
}

.hidden {
  display: none;
  &.show {
    display: block;
  }
}

.warranty-upload-btn {
  width: 226px;
  height: 57px;
  border-radius: 10px;
  background-color: #86a0ba;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  border: 0px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.warranty-continue-button {
  background : #204367 !important;
  color:white !important;
}
.issue-upload {
  .dz-preview {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .dz-details {
    width: 70px;
    margin-right: 10px;
  }

  .dz-filename {
    width: 270px;
  }
}

input,
select {
  &.error {
    border-bottom: solid 2px #ff0000 !important;
    margin-bottom: 0px !important;
  }
}

textarea {
  &.error {
    border: solid 2px #ff0000 !important;
    margin-bottom: 0px;
  }
}

.paypal-status-message{
  display: block;
}  
.paypal-status-message.none{
  display:none;
}
.step4{
  text-align: center;
}

.topMargin10{
  margin-top:2rem;
}
.form-fields {
  &.center{
      text-align: center;
  }
}

.radio{    
  margin:auto;
  min-width: 25%;
  display: inline-block;
  label{        
      margin: auto;
      min-width: 25%;
      display: inline-block;
      padding: 1rem 2rem;
      border:2px solid #d7d9dd;
      border-radius: 5px;
  }
  input{
      margin-right:10px;;
      -webkit-appearance:auto;
  }
}