.upload_progress {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.54);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;  
  .progress-bar {
    width: 50%;
    height: 33px;
    background: #fff;
    border-radius: 20px;
    .current-progress {
      height: 100%;
      width: 0%;
      background: #5ca0e6;
      border-radius: 20px;
    }
  }
}