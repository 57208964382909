@import "./vote", "./estimate", "./warranty_form", "./thank_you", "./lightbox",
  "./agm_difference", "./upload_progress", "./bootstrap-grid",
  "./utilities/spacing", "./warranty_claim_form", "./payment_receipt",
  "./finished_basements";

body,
html,
* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
}

input,
button,
select,
textarea {
  font-family: "Lato", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.section-title {
    color: #2e4659;
    font-size: 60px;
    font-weight: 600;
    text-align: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &_title {
    text-align: center;
    font-size: 55px;
    margin-bottom: 70px;
    font-weight: 500;
  }

  .link_list_wrap {
    display: grid;
    gap: 30px;

    .link_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      gap: 70px;

      .link_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 135px;

        &_button {
          border: none;
          outline: none;
          background: transparent;
          padding: 5px;
        }

        .image_wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
          border: 1px solid #f9f6f6;
          margin-bottom: 20px;
          background: white;
          cursor: pointer;

          & .scaled {
            transform: scale(1.8);
          }

          &:hover {
            background: linear-gradient(45deg, #272d83, #009bc5);
            box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.1);
            border: 1px solid #009bc5;

            img {
              filter: brightness(0) invert(1);
            }
          }

          &.active {
            background: linear-gradient(45deg, #272d83, #009bc5);
            box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.1);
            border: 1px solid #009bc5;

            img {
              filter: brightness(0) invert(1);
            }
          }

          img {
            width: 50%;
            object-fit: contain;
          }

        }

        .image_title {
          font-size: 20px;
          font-weight: 600;
          // width: 200px;
          overflow-wrap: break-word;
          text-align: center;
        }

        .link_item_description {
          display: flex;
          margin-top: 20px;
          background: grey;
          color: white;
          border-radius: 5px;
          padding: 5px 10px;
          text-align: center;
          font-size: 12px;
        }
      }
    }



    .link_list.warranty{

      grid-template-columns: repeat(6, 1fr);

      @media screen and (max-width: 1300px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }


    .link_list.promot {
      display: flex;
      z-index: 1;
      margin-left: 0px;
      grid-template-columns: none;
      gap: 38px;
      padding: 0;
      max-height: 150px;

      .link_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90px;
        flex-wrap: nowrap;

        .image_wrap{
          width: 80px;
          height: 80px;
          margin-bottom: 3px;
        }

        .image_title {
          font-size: 10px;
          display: flex;
          color: #224c74;

          font-size: 0.9em !important;
          line-height: 20px !important;
        }
      }
    }
  }

  .link_item.mobile {
    display: none !important;
  }

  @media screen and (min-width: 1600px) {
    &_title {
      font-size: 75px;
    }
    .link_list_wrap {
      gap: min(4vw, 4vh);

      .link_list {
        gap: min(4vw, 4vh);

        .link_item {
          width: max(10vw, 10vh);

          .image_wrap {
            width: max(8vw, 8vh);
            height: max(8vw, 8vh);
          }

          .image_title {
            font-size: min(2vw, 2vh);
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    &_title {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 40px;
    }
    .link_list_wrap {
      .link_list {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;

        .link_item {
          // width: 180px;

          .image_title {
            font-weight: 600;
          }

          .image_wrap {
            // width: 150px;
            // height: 150px;
            margin-bottom: 0;

            img {
              width: 40%;
            }
          }
        }
      }

      .link_list.promot {
        display: grid;
        z-index: 1;
        gap: 18px;
        grid-template-columns: repeat(3, 1fr) !important;
        flex-direction: column;
        max-height: 310px;
      }
      
    }




  }
  @media screen and (max-width: 660px) {

    margin-bottom: 10px;
    &_title {
      font-size: max(24px, 7.5vw);
      margin-bottom: 40px;
    }



    .link_list_wrap {
      margin-bottom: calc(100vh - 562px);
      gap: 40px;

      .link_list {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 40px;

        .link_item.desktop {
          display: none !important;
        }
        .link_item.mobile {
          display: flex !important;
        }
      }

      .link_list.promot {
        display: grid;
        z-index: 1;
        gap: 18px;
        grid-template-columns: repeat(3, 1fr) !important;
        flex-direction: column;
        max-height: 310px;
      }

    }

  }
  @media screen and (max-width: 380px) {
    .link_list_wrap {
      .link_list {
        .link_item {
          transform: scale(1.1);
          margin-bottom: 28px;
        }
      }
      .link_list.promot {
        transform: scale(0.85);
        font-size: 0.8em;
      }
    }
    .link_list_wrap {
      margin-bottom: calc(100vh - 462px);
    }
  }
}

.floating-form {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
 
  padding: 20px;
  top: 0px;
  left: 0px;
  background: #e5e5e5;
  z-index: 998;
  font-family: "Proxima Nova", Arial, Helvetica, sans-serif;
  color: #4c4c4c;
  font-size: 16px;
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid #cacaca;
  .container{
    max-width: 1200px;
  }

  .offer-top {
    text-align: center;
  }

  @media screen and (max-width: 1989px) {
    padding: 3px 20px 15px 20px;
  }

  @media screen and (max-width: 980px) {
    // .floating-form {
      display: none;
    // }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(9px, 4px) scale(0.65);
  }

  .float-logo {
    flex: 0 0 115px;
    &:after {
      content: "";
      border-right: 1px solid #c2c2c2;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      right: -25px;
    }
  }

  .MuiInputLabel-outlined {
    font-size: 14px;
    color: #454545;
    transform: translate(14px, 13px) scale(1);
  }

  h2 {
    color: #839069;
    display: inline-block;
    text-decoration: none;
    font-size: 18px;
    padding: 0;
    margin: 0;

    &.offer-text {
      color: #454545;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 2px solid #454545;
      display: inline-block;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      font-weight: bold;
    }

    text-align: center;
    // color: #fff;
    text-shadow: none;
    font-size: 26px;
    line-height: 26px;
  }

  button {
    &.MuiFab-root {
      box-shadow: none;
      height: 38px;
      border-radius: 4px;
      margin-left: 8px;
    }
  }

  .form-fields {
    display: flex;

    img {
      width: 140px;
      margin-right: 10px;
    }

    .MuiInputBase-input {
      border: 1px solid #333;
      border-radius: 0;
      background: white;
      padding: 8px 30px 8px 8px;
      font-size: 13px;
      height: 38px;
      box-sizing: border-box;
    }

    .MuiFormControl-root {
      margin-left: 8px;
    }
  }

  // .MuiFormLabel-root {
  //   color: white;
  // }

  // .MuiInput-underline {
  //   &:before {
  //     border-bottom: 1px solid rgb(255, 255, 255);
  //   }

  //   input {
  //     color: white;
  //     &::placeholder {
  //       color: white;
  //     }
  //   }
  // }
}

.floating-call-btn {
  @media screen and (max-width: 900px) {
    display: none;
  }
  position: fixed;
  bottom: 0px;
  right: 20px;
  padding: 10px 20px;
  background: #1d3c5a;
  color: #fff;
  z-index: 99;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  a {
    color: #fff;
    font-weight: 600;
  }

  &:before {
    font-family: "FontAwesome";
    content: "\f095";
    font-size: 26px;
    line-height: 30px;
    margin-right: 11px;
    margin-top: -3px;
  }
}
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

p {
  color: #5a6a76;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

a {
  color: #5a6a76;
  font-weight: 600;
  &.blue {
    color: #1986f9;
  }
}

.container {
  width: 90%;
  max-width: 100%;
  margin: 0 auto;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.sticky-quote-container {
  display: none;
}

.MuiButton-containedPrimary {
  background-color: #1d3c5a;
}

.home-info-container {
  overflow-x: hidden;
  background-color: #f5f5f5;
  padding-top: 1.5rem;
  .home-warranty {
    background-image: linear-gradient(0deg, #191818f2, rgba(33, 32, 32, 0.92));
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    padding: 3rem 0 8rem 0;
    position: relative;
    .home-warrany-icon {
      width: 4rem;
      height: 4rem;
      position: absolute;
      left: calc(50% - 2rem);
      top: -2rem;
      border: solid 2px #2f2f2f;
      border-radius: 50%;
      background-color: #ffffff;
      padding: 10px 0px 0 8px;
      div {
        background-image: url("../Images/warranty.png");
        background-size: contain;
        background-repeat: no-repeat;
        height: 90%;
        width: 90%;
      }
    }
    p {
      margin: 0;
      color: #ffffff;
      font-weight: 900;
      &:first-child {
        font-size: 16px;
      }
      &:last-child {
        font-size: 14px;
      }
    }
    span {
      font-weight: 100;
      font-size: 13px;
    }
  }

  .home-warranty-2 {
    background-image: linear-gradient(0deg, #191818f2, rgba(33, 32, 32, 0.92));
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    padding: 3rem 0 1rem 0;
    position: relative;
    .home-warrany-icon {
      width: 7rem;
      height: 7rem;
      position: absolute;
      left: calc(50% - 3.5rem);
      top: -4rem;
      border: solid 2px #2f2f2f;
      border-radius: 50%;
      background-color: #ffffff;
      padding: 10px 0px 0 8px;
      div {
        background-image: url("../Images/warranty.png");
        background-size: contain;
        background-repeat: no-repeat;
        height: 90%;
        width: 90%;
        margin-top: 0.4rem;
      }
    }
    p {
      margin: 0;
      color: #ffffff;
      font-weight: 900;
      font-size: 2rem;
      margin: 1rem;
    }
    span {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }

  .home-info-area {
    text-align: center;
    margin-bottom: 3.5rem;
    h2 {
      font-size: 14px;
      color: #00b1e3;
      margin-top: 0;
      margin-bottom: 0.2rem;
    }
    p {
      color: #000000;
      margin-top: 0;
      padding: 0 5rem;
      font-size: 13px;
      font-weight: 100;
      line-height: 1.4rem;
    }
  }
  .our-process-container {
    background-color: #ffffff;
    box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.25),
      0px 0px 0px 0px #efefef;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0.5rem;
    width: 70%;
    @media (min-width: 300px) and (max-width: 450px) {
      margin-left: 2rem;
      margin-right: 2rem;
      width: 80%;
      margin-top: -7rem;
    }
    .our-process {
      margin: auto;
      justify-content: center;
      padding-left: 4rem;
      padding-right: 4rem;
      @media (min-width: 300px) and (max-width: 450px) {
        padding: 0;
      }
      .col-sm-12 {
        margin-top: -0.4rem;
      }
      h2 {
        font-size: 2.5em;
        font-weight: 900;
        @media (min-width: 300px) and (max-width: 450px) {
          font-size: 1.4em;
        }
      }
      ol {
        padding: 0 1rem 0.5rem 1rem;
        margin: 0;
        li {
          list-style: none;
          font-weight: 500;
          font-size: 1.7rem;
          display: flex;
          align-items: flex-start;
          margin-bottom: 8px;
          @media (min-width: 300px) and (max-width: 450px) {
            font-size: 1em;
            font-weight: 500;
          }
          img {
            list-style: none;
            width: 2rem;
            height: 2rem;
            margin-right: 0.5rem;
            margin-top: 0.1rem;
            @media (min-width: 300px) and (max-width: 450px) {
              width: 1rem;
              height: 1rem;
            }
          }
          span {
            font-size: 1rem;
            font-weight: 700;
            background-color: #8ec53e;
            color: #fff;
            padding: 5px 10px;
            border-radius: 50%;
            margin-right: 0.5rem;
            @media (min-width: 300px) and (max-width: 450px) {
              // background-color: transparent;
              // color: #000;
              // margin-right: 0;
              font-size: 0.9em;
              font-weight: 500;
              padding: 4px 8px;
              margin-top: 0.1rem;
              margin-bottom: 0.1rem;
            }
          }
        }
      }
    }
  }
  .our-process-container-2 {
    background-image: linear-gradient(0deg, #191818f2, rgba(33, 32, 32, 0.985));
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 7rem;
    h1 {
      color: #fff;
      text-align: center;
      font-size: 2.5rem;
    }
    img {
      margin: auto;
    }
  }
  .best-houz {
    height: 140px;
    margin-top: 1rem !important;
    margin: 2rem auto;
    text-align: center;
  }
}

.about-container {
  @media (min-width: 300px) and (max-width: 450px) {
    background-color: #233048;
    padding: 30px !important;
    .about-container-wrapper {
      position: relative;
      background-color: #efecec;
      border-radius: 2rem;
      margin-bottom: 4rem;
      margin-top: 2rem;
      .about-icon-container {
        width: 4rem;
        height: 4rem;
        background-image: url("../Images/tick.png");
        position: absolute;
        left: calc(50% - 2rem);
        top: -2rem;
        background-size: cover;
      }
      .about-icon-container.eye {
        background-image: url("../Images/eye.png");
      }
      .about-container-wrapper-header {
        text-align: center;
        padding: 2rem;
        h2 {
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 0;
        }
        span {
          color: #596375;
          font-size: 12px;
        }
      }
      .about-container-wrapper-body {
        padding: 0.5rem 1rem 0.5rem 2rem;
        background-color: #ffffff;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        h2 {
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          margin: 0;
        }
        ul {
          padding: 0;
          color: #596375;
          font-size: 12px;
        }
      }
    }
  }
}

.design-center-slider .slick-slider .slick-list {
  // .slick-slide {
  //   margin-left: 6rem;
  //   margin-right: 1rem;
  //   @media (min-width: 300px) and (max-width: 450px) {
  //     margin-left: 2rem;
  //     margin-right: 1rem;
  //   }
  // }
  a {
    text-align: center;
  }
}
.slick-center {
  transform: scale(1.8);
  padding: 1rem;
  @media (min-width: 300px) and (max-width: 450px) {
    transform: none;
  }
}
.agm-family-slider-container {
  box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #efefef;
  padding: 1rem;
  text-align: center;
  border-radius: 2%;
  &.review {
    min-width: 15rem;
    height: 10rem;
    overflow: hidden;
    background: white;
    margin: auto;
    @media screen and (max-width: 676px) {
      width: 10rem;
      min-width: 10rem;
    }
    .review-text {
      overflow: auto;
      height: 5rem;
      font-size: 10px;
      padding: 0;
      color: #2f2f2f;
    }
    .review-text::-webkit-scrollbar {
      display: none;
    }
  }
  span {
    display: block;
    text-align: start;
    padding-top: 0.5rem;
    padding-bottom: 0.1rem;
    //padding: 1rem 2rem 0 2rem;
    font-size: 12px;
    color: #596375;
    font-weight: 400;
  }
}

img {
  max-width: 100%;
}

.mobile-none {
  @media (min-width: 300px) and (max-width: 450px) {
    display: none !important;
  }
}

.iPad-display {
  display: none !important;
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block !important;
  }
}

.desktop-none {
  display: none !important;
  @media (min-width: 300px) and (max-width: 450px) {
    display: block !important;
  }
}

.btn {
  text-decoration: none;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  &.orange-btn {
    box-shadow: 0 9px 54px rgba(248, 253, 245, 0.16);
    border-radius: 38px;
    background-color: #224463;
    background-image: linear-gradient(
      -240deg,
      rgba(251, 168, 73, 0.3) 0%,
      rgba(227, 101, 0, 0.3) 100%
    );
    padding: 18px 29px;
    transition: all 0.5s ease;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 38px;
    text-transform: uppercase;
    letter-spacing: 3.6px;
    &:hover {
      background-color: #eea34d;
    }
    @media screen and (max-width: 1399px) {
      padding: 10px 25px;
    }
  }

  &.dark-blue-btn {
    border-radius: 45px;
    background-color: #192e44;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 31px 83px;
  }

  &.blue-btn {
    border-radius: 3px;
    background-color: #5bb4ff;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 20px 33px;

    &.red {
      background-color: #e30057;
      cursor: pointer;
    }

    &.orange {
      background-color: #ffa500;
      cursor: pointer;
    }

    &.sm {
      padding: 5px 23px;
      font-size: 15px;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  &.blue3d-btn {
    padding: 15px 40px;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    display: table;
    border-radius: 4px;
    background-color: #e30057;
    transition: all 0.2s;
    text-decoration: none;
    font-weight: 600;
    box-shadow: 3px 3px 4px #3e3e3e !important;
    margin: 0 auto;
    // @media screen and (max-width: 500px) {
    //   width: 100%;
    // }
  }

  &.home-banner-blue-btn {
    border-radius: 3px;
    background-color: #5bb4ff;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    line-height: 38px;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 10px 23px;
    //margin-top: 60px;
    @media screen and (max-width: 500px) {
      margin: 0 auto;
      margin-left: 10%;
      margin-right: 10%;
      display: block;
    }
  }

  &.light-blue-btn {
    border-radius: 41px;
    background: #00aede;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 24px 62px;

    &.orange {
      background: #ffa500;
    }
    &.red {
      background: #e30057;
    }
    @media screen and (max-width: 411px) {
      font-size: 16px;
    }
  }

  &.light-blue-sq-btn {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
    background: #00aede;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 24px 62px;
    @media screen and (max-width: 411px) {
      font-size: 16px;
    }
    @media (min-width: 1250px) and (max-width: 1550px) {
      padding: 24px 32px;
      font-size: 16px;
    }
  }
  &.light-blue-sq-btn-2 {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
    background: #00aede;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 20px 35px;
    @media (min-width: 300px) and (max-width: 450px) {
      padding: 15px 20px;
      font-size: 12px;
      margin: auto;
      margin-left: 2.2rem;
      margin-bottom: 0.5rem;
    }
  }

  &.red-btn {
    border-radius: 0px;
    background-color: #e30057;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 15px 20px;
    display: none;
    &.sm {
      padding: 5px 23px;
      font-size: 15px;
    }
    @media screen and (max-width: 500px) {
      // margin-bottom: 1rem !important;
      display: block;
      margin: auto;
      width: 65%;
      padding: 15px 10px;
    }
  }
  &.red-btn-2 {
    border-radius: 0px;
    background-color: #e30057;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 15px 20px;

    &.orange {
      background-color: #ffa500;
    }
    &.red {
      background-color: #e30057;
    }
    &.sm {
      padding: 5px 23px;
      font-size: 15px;
    }
    @media screen and (max-width: 500px) {
      // margin-bottom: 1rem !important;
      display: none;
      margin: auto;
      width: 80%;
    }
  }
  &.red-btn-d {
    background-image: url("../Images/red-banner.svg");
    color: #ffffff;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-left: 40px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    &.sm {
      display: none;
      padding: 5px 23px;
      font-size: 15px;
    }
    @media screen and (max-width: 500px) {
      display: none;
      margin: auto;
      width: 80%;
    }
  }
  &.green-btn {
    background-image: url("../Images/green-banner.svg");
    color: #ffffff;
    font-size: 12.5px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-top: 7px;
    padding-bottom: 10px;
    display: none;
    &.sm {
      padding: 5px 5px;
      font-size: 15px;
    }
    @media screen and (max-width: 500px) {
      display: block;
      margin: auto;
      width: 80%;
    }
  }
}

// .hover-text:hover {
//   font-size: 20px;
// }

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

#top_header {
  min-height: 70px;
  background-color: #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 40px;
  @media screen and (max-width: 822px) {
    flex-direction: column;
  }
  .financeit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 520px) {
      flex-direction: column;
      padding-top: 5px;
    }
    a {
      margin-right: 35px;
      @media screen and (max-width: 520px) {
        margin-right: 0px;
      }
    }

    p {
      color: #7f8e9a;
      font-size: 18px;
      font-weight: 300;
      line-height: 38px;
      margin: 0px;
    }
  }

  .left-top-nav {
    @media screen and (max-width: 1074px) {
      display: none;
    }
    ul {
      padding: 0px;
      margin: 0px;
      li {
        list-style-type: none;
        display: inline-block;
        margin-right: 46px;
        a {
          color: #7f8e9a;
          font-size: 18px;
          font-weight: 300;
          line-height: 38px;
          text-decoration: none;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .top-social-nav {
    @media screen and (max-width: 1230px) {
      display: none;
    }
    ul {
      margin-bottom: 0px;
      li {
        list-style-type: none;
        display: inline-block;
        margin-right: 38px;
        a {
          opacity: 0.9;
          color: #204367;
          font-size: 24px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.header-call-us {
  color: #224463;
  @media only screen and (max-width: 630px) {
    display: none;
  }
  svg {
    fill: #224463;
    margin-right: 16px;
    height: 23px;
    transform: rotate(90deg);
    margin-top: 16px;
    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      margin-right: 0;
    }
  }

  .header-call-us-container {
    background: #00aede;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }

  .header-call-us-container img {
    fill: #224463;
    margin-left: 7px;
    height: 18px;
    transform: rotate(90deg);
    margin-top: 7px;
  }

  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    text-transform: uppercase;
    letter-spacing: 2px;
    @media screen and (max-width: 411px) {
      font-size: 17px;
    }
  }

  a {
    color: #224463;
    text-decoration: none;
    &:hover {
      color: #224463;
      text-decoration: none;
    }
  }
}

#main-header .main-nav ul.open {
  @media (min-width: 300px) and (max-width: 450px) {
    background-color: #224463;
  }
}

.sticky-nav-top-margin {
  @media (min-width: 300px) and (max-width: 450px) {
    position: relative;
    // top: 80px;
  }
}

/* Curl Top Left */
.hvr-curl-top-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-top-left:before {
  pointer-events: none;
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(135deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#000000');
  /*For IE7-8-9*/
  z-index: 1000;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: width, height;
  transition-property: width, height;
}
.hvr-curl-top-left:hover:before,
.hvr-curl-top-left:focus:before,
.hvr-curl-top-left:active:before {
  width: 25px;
  height: 25px;
}

/* SHIFT for nav */
.shift ol li a {
  position: relative;
  z-index: 1;
}
.shift ol li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 50%;
  content: "";
  background: #204367;
  visibility: none;
  opacity: 0;
  z-index: -1;
}
.shift ol li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.shift ol li a,
.shift ol li a:after,
.shift ol li a:before {
  transition: all 0.5s;
}

/* stroke for nav */
.stroke {
  position: relative;
}
.stroke:after {
  position: absolute;
  bottom: -1.5px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: "";
  color: transparent;
  background: #204367;
  height: 2px;
}
.stroke:hover:after {
  width: 100%;
  transition: all 0.35s;
}

/* Hover for Buttons */
@keyframes hover {
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.hover {
  display: inline-block;
  transition-duration: 0.5s;
  transition-property: transform;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
}
.hover:hover {
  transform: translateY(-10px);
  animation-name: hover;
  animation-duration: 1.25s;
  animation-delay: 0.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.footerInfoBar {
  padding: 5px 15px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 110px;
  bottom: 80px;
  display: none;
  flex-direction: row;
  .itemInfo {
    margin-right: 20px;
    color: white;
    .border {
      border-right: 2px solid white;
    }
    b,
    span {
      padding: 0px 30px;
    }
    b {
      display: block;
    }
    span {
      display: block;
    }
  }
  @media screen and (max-width: 1400px) {
    right: 50px;
  }
  @media screen and (max-width: 1024px) {
    bottom: 80px;
    right: 0px;
    padding: 8px 5px 8px 5px;
  }
  @media screen and (max-width: 480px) {
    font-size: 7pt;
    right: 0px;
    padding: 2px 0px 2px 0px;
    bottom: 0px;
    .itemInfo {
      margin-right: 0px;
      b,
      span {
        padding: 0px 10px;
        text-align: center;
        height: 15px;
        line-height: 15px;
        margin-right: 0px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    bottom: 0px;
  }
}
.header-section {
  .hvr-grow {
    @media screen and (max-width: 1367px) {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  z-index: 999;

  @media screen and (min-width: 1366px) {
    position: relative;
    right: initial;
    margin-top: 0px;
  }
  // @media screen and (min-width: 1025px) and (max-width: 1366px) {
  //   position: absolute;
  //   margin-top: 220px;
  //   right: 20px;
  // }
  @media screen and (max-width: 1024px) {
    margin: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: auto;
    display: block;
    text-align: center;
  }
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Sweep To Right */
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover,
.hvr-sweep-to-right:focus,
.hvr-sweep-to-right:active {
  color: white;
}
.hvr-sweep-to-right:hover:before,
.hvr-sweep-to-right:focus:before,
.hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

#main-header {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 40px;
  min-height: 120px;
  top: 0;
  @media only screen and (max-width: 480px) {
    padding: 0px !important;
  }
  @media only screen and (max-width: 768px) {
    min-height: 80px;
    padding: 18px 0 12px 0px;

    margin-top: 60px;
    top: 50px;

    // padding: 0px !important;
  }
  @media screen and (max-width: 1399px) {
    flex-wrap: wrap;
    padding: 10px 20px;
    z-index: 9999;
  }
  .logo {
    @media screen and (max-width: 1399px) {
      text-align: center;
    }
    @media screen and (max-width: 999px) {
      margin: 0px 20px;
    }
    @media screen and (max-width: 630px) {
      display: none;
    }
    a {
      img {
        width: 178px;
      }
    }
  }

  .paypal-buttons-context-iframe {
    width: 550px;
    @media screen and (max-width: 630px) {
      width: 100%;
    }
  }

  .gold_banner {
    margin-left: 1rem;
    margin-right: 1rem;
    position: absolute;
    width: 180px;
    z-index: 20000;
    right: inherit;
    left: 210px;
    top: 0px;
    cursor: pointer;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,.4));

    @media (min-width: 1700px) {
      // right: 0px;
      // left: initial;
      // top: 120px;
      right: inherit;
      left: 280px;
      top: 0px;
    }
    @media (min-width: 1440px) and (max-width: 1669px) {
      left: 240px;
    }
    @media (min-width: 1141px) and (max-width: 1440px) {
      // right: 0px;
      // left: initial;
      // top: 120px;
   
      right: inherit;
      left: 210px;
      top: 0px;
    }
    @media (min-width: 1024px) and (max-width: 1140px) {
      width: 160px;
    }
    @media screen and (max-width: 1411px) {
      right: inherit;
      left: 200px;
      top: 0px;
    }
    @media screen and (max-width: 1024px) {
      right: 0px;
      left: initial;
      top: 0px;
      display: none;
    }
    // @media screen and (max-width: 630px) {
    //   display: none;
    // }

    a {
      img {
        width: 200px;

        @media screen and (max-width: 768px) {
          width: 180px;
        }
        @media screen and (max-width: 768px) {
          width: 150px;
        }
      }
    }
  }

  .free-consultation-btn {
    @media screen and (max-width: 630px) {
      display: none;
    }
  }

  .call-text {
    @media screen and (max-width: 630px) {
      display: none;
    }
  }

  .main-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    margin-right: 30px;

    .promotion-mobile {
      display: none;
      cursor: pointer;
      filter: drop-shadow(0 2px 4px rgba(0,0,0,.4));

      @media screen and (max-width: 1024px) {
        width: 170px;
        top: 0;
        position: absolute;
        display: block;
      }
      @media screen and (max-width: 630px) {
        width: 143px;
        top: -2px;
        position: relative;
        display: block;
      }
      @media screen and (max-width: 450px) {
        position: relative;
        top: -7px;
        width: 120px;
      }

    }
    .logo-2 {
      @media (min-width: 300px) and (max-width: 450px) {
        display: block;
        width: 120px;
        margin-top: 3px;
      }
      @media (min-width: 630px) {
        display: none;
      }
      a {
        img {
          width: 178px;
        }
      }
    }

    @media (min-width: 630px) and (max-width: 1399px) {
      margin-left: 0px;
      margin-top: 50px;
    }

    @media screen and (min-width: 1025px) and (max-width: 1366px) {
      margin-right: 20px;
      text-align: right;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-right: 0px;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      ul {
        text-align: center;
      }
    }

    @media screen and (max-width: 630px) {
      height: 90px;
      margin-bottom: 30px;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    @media screen and (max-width: 450px) {
      margin-bottom: 0px;
    }

    .mobile-menu-icon {
      display: none;
      float: right;
      font-size: 29px;
      fill: #204367;
      height: 26px;

      @media screen and (max-width: 630px) {
        display: inline-block;
      }
    }

    ul {
      padding: 0px;
      margin: 0px;
      @media screen and (max-width: 630px) {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.15s ease-out;
        position: absolute;
        top: 0;
        left: 0px;
        width: 100%;
        background: rgba(255, 255, 255, 0.92);
        display: none;
        &.open {
          min-height: 100vh;
          transition: max-height 0.25s ease-in;
          z-index: 9999;
          display: flex;
          justify-content: center;
          align-items: center;
          li {
            padding: 10px 0;
          }
          .social-menu-mobile {
            position: absolute;
            top: unset;
            left: 0;
            right: 0;
            bottom: 0;
          }
          .quote-container {
            margin-top: 8%;
          }
        }
        li {
          padding: 10px;
          width: 100%;
        }
      }
      li {
        list-style-type: none;
        display: inline-block;
        margin-right: 15px;
        a {
          text-decoration: none;
          color: #425b77;
          font-size: 12px;
          font-weight: 600;
          // padding: 14px 0px;
          line-height: 40px;
          text-transform: uppercase;
          // padding-bottom: 3px;
          letter-spacing: 1px;
          @media (min-width: 300px) and (max-width: 450px) {
            color: #fff;
            font-weight: normal;
            text-transform: capitalize;
          }
        }
        a:hover {
          color: #fff;
          padding: 3px 4px;
        }

        ol {
          padding: 0;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
      hr {
        display: none;
        @media (min-width: 300px) and (max-width: 450px) {
          display: block;
          width: 150px;
          margin: 0 auto;
          height: 0.1px;
          background-color: #666;
          opacity: 0.3;
        }
      }
      .close-menu {
        background-color: transparent;
        border: solid 3px #ffffff;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        top: 2rem;
        line-height: 1rem;
        font-weight: 900;
        padding: 0.3rem;
        span {
          color: #ffffff;
        }
      }
      @media (min-width: 300px) and (max-width: 450px) {
        .quote-container {
          margin-bottom: 3rem !important;
        }
      }
    }
  }
}
#mobileBanner {
  height: 300px;
  position: relative;
  background-size: cover;
  background-position-x: right;
  @media screen and (max-width: 480px) {
    height: 254px;
    position: relative;
    background-size: cover;
    background-position-x: right;
    background-size: 160%;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: 320px) {
    height: 226px;
  }
}

#home-banner {
  min-height: 670px;
  height: 90vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: right;

  .promo2{
    width: 200px;
    top: 15px;
    position: absolute;
    cursor: pointer;
    left: 20px;
    -webkit-filter: drop-shadow(2px 4px 6px rgba(0,0,0,.3));
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,.3));

    &:hover{
      filter: drop-shadow(2px 4px 6px rgba(0,0,0,.6));
    }
  }
  // clip-path: polygon(0 0, 100% 0, 100% 92%, 0% 100%);
  // background-image: url('../Images/DSC1073.jpg');
  @media (min-width: 768px) and (max-width: 1024px) {
    top: 0 !important;

    .promo2{
      display: none;
    }
  }
  @media screen and (max-width: 1399px) {
    position: relative;
    top: 0px;
  }
  margin-bottom: 210px;
  @media screen and (max-width: 1064px) {
    margin-bottom: 220px;
    .promo2{
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    min-height: unset;
    background-position: center;

    .promo2{
      width: 112px;
      margin: 10px;
    }

  }

  @media screen and (max-width: 534px) {
    margin-bottom: 380px;
    background-size: 100% 62%;
    background-position-y: 0;
  }

  &.estimate-header-contain {
    height: 810px;
    margin-bottom: 100px;
    @media screen and (max-width: 1399px) {
      height: 840px;
    }
    @media screen and (max-width: 1065px) {
      height: auto;
    }
  }

  .home-banner-content {
    position: absolute;
    left: 0px;
    bottom: -168px;
    width: 1328px;
    max-width: 100%;
    // min-height: 336px;
    background-color: #243048;
    display: flex;
    align-items: center;
    padding: 20px 120px;
    @media screen and (max-width: 1770px) {
      width: 1178px;
      padding: 40px 60px;
    }

    @media screen and (max-width: 1610px) {
      width: 1127px;
    }

    @media screen and (max-width: 1006px) {
      flex-direction: column;
    }

    @media screen and (max-width: 878px) {
      padding: 40px 60px;
    }

    @media screen and (max-width: 534px) {
      //bottom: -318px;
      bottom: -210px;
      min-height: 500px;
    }

    .left {
      width: 50%;
      @media screen and (max-width: 1006px) {
        width: 100%;
      }
    }

    .right {
      flex: 1;
      margin-left: 100px;
      @media screen and (max-width: 1006px) {
        margin-left: 0px;
      }
    }

    h1 {
      color: #ffffff;
      font-family: sans-serif;
      font-size: 44px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -1.15px;
      line-height: unset;
      @media screen and (max-width: 1610px) {
        font-size: 33px;
      }

      @media screen and (max-width: 417px) {
        font-size: 20px;
      }
    }

    span {
      &.sub-head {
        color: #dbddf3;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.8px;
        line-height: unset;
      }
    }

    p {
      font-size: 22px;
      font-weight: 300;
      line-height: 26px;
    }

    a {
      &.btn.blue-btn {
        box-shadow: none;
        border-bottom: 0px;
        font-size: 15px;
      }
    }

    .button-desktop {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .button-mobile {
      @media screen and (min-width: 600px) {
        display: none;
      }
    }

    .banner-testimonial {
      position: absolute;
      right: -420px;
      width: 380px;
      bottom: 10px;
      text-align: center;
      @media screen and (max-width: 1570px) {
        display: none;
      }
      p {
        color: #243048;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.09px;
        margin: 0px;
      }
      .author {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        .author-image {
          img {
            margin-right: 10px;
          }
        }

        h5 {
          color: #243048;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1.6px;
          margin: 0px;
        }
      }

      &:before {
        content: '"';
        position: absolute;
        top: 0px;
        left: -30px;
        opacity: 0.05;
        color: #243048;
        font-family: cursive;
        font-size: 110px;
      }
    }
  }
}

#mobile-home-banner {
  width: 100%;
  background-color: #243048;
  // margin-top:-5px;
  .mobile-banner-content {
    display: flex;
    align-items: center;
    // padding: 0 20px 10px;
    padding-top: 8px;
    text-align: center;
    .left {
      width: 50%;
      @media screen and (max-width: 1006px) {
        width: 100%;
      }
    }

    h1 {
      color: #ffffff;
      font-family: sans-serif;
      font-size: 44px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -1.15px;
      line-height: unset;
      margin: 10px;

      @media screen and (max-width: 1610px) {
        font-size: 33px;
      }

      @media screen and (max-width: 417px) {
        font-size: 26px;
      }
    }

    span {
      &.sub-head {
        color: #dbddf3;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.8px;
        line-height: unset;
      }
    }
  }
  .slick-slide img {
    width: 100vw;
    max-height: 250px;
  }
}

@keyframes ani {
  0% {
    color: #111;
    text-shadow: none;
  }
  25% {
    color: #333;
    text-shadow: none;
  }
  50% {
    color: #555;
    text-shadow: 0 0 1px #e30057;
  }
  75% {
    color: #777;
    text-shadow: 0 0 2px #e30057;
  }
  100% {
    color: #ccc;
    text-shadow: 0 0 3px #e30057;
  }
}

.glow {
  animation: ani 1.5s linear infinite;
  animation-delay: 3s;
  margin: auto;
}

.national-homeshow {
  width: 100%;
  height: 25px;
  background-color: #fff;
  top: 80px;
  color: black;
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  @media (min-width: 500px) {
    display: none !important;
  }
}
.careers {
  .container {
    .row {
      .description {
        background: #f8f8f8;
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          .dots{
            display: none;
          }
        }
      }
      .description::after {
        position: absolute;
        top: 0;
        right: 0px;
        width: 1000px;
        height: 100%;
        background-color: transparent;
        box-shadow: 1000px 0px #f8f8f8;
        content: "";
        z-index: -1;
      }
    }
  }
  .coreValues {
    ul {
      font-size: 22px;
      padding-left: 20px;
    }
    a {
      background: linear-gradient(90deg, #46bfee, #29dce0);
    }
  }
  .qualitiesDescription {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 97%,
      rgba(245, 245, 245, 1) 100%
    );
    li {
      color: #5a6a76;
    }
  }
  .quailitiesGallery {
    box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.21);
  }
  .quailitiesGallery,
  .qualitiesDescription {
    padding: 2rem !important;
    @media screen and (max-width: 767px) {
      background: #fff;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
    ul {
      font-size: 20px;
      line-height: 20pt;
    }
  }
  .qualitiesDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      h2 {
        text-align: center;
      }
    }
  }
  .quailitiesGallery-1 {
    box-shadow: 5px 6px 8px -1px rgba(0, 0, 0, 0.21);
  }
  .quailitiesGallery-1,
  .quailitiesGallery {
    @media screen and (max-width: 600px) {
      padding: 0 !important;
    }
    small {
      font-size: 18px;
      @media screen and (max-width: 767px) {
        padding: 15px;
        padding-bottom: 0;
      }
    }
  }
  h2,
  h3,
  .title-submit,
  .title-blue-border {
    text-shadow: 2px 2px #d8d8d8;
  }
}
.careers {
  .container {
    width: 2200px;
  }
  #submit_resume {
    margin: 0 40px;
    justify-content: center;
    @media screen and (max-width: 1440px) {
      margin: unset;
    }
    input {
      box-shadow: 0px 0px 4px 0px #00000059;
    }
  }
}
#inner-banner {
  width: 100%;
  background-image: url("../Images/banner-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &.blog {
    background-image: url("../Images/about_us_banner.png");
    background-position: left;
  }
  &.careers {
    background-image: url("../Images/banner-bg-career.jpg");
    height: 500px;
    @media screen and (max-width: 1600px) {
      height: 450px;
    }
    .inner-banner-content {
      h1 {
        margin-top: 14rem;
        font-weight: 100;
      }
    }
    @media screen and (max-width: 1024px) {
      height: unset;
      .inner-banner-content {
        h1 {
          margin-top: unset;
        }
      }
    }
  }
  .inner-banner-content {
    display: flex;
    min-height: 329px;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
      font-size: 60px;
      color: #fff;
    }

    p {
      opacity: 0.8;
      color: #ffffff;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }
  }
}
.banner-content {
  width: 1300px;
  max-width: 100%;
  // margin: 0 auto;
  padding-top: 130px;

  @media screen and (max-width: 1337px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 1065px) {
    flex-direction: column-reverse;
    padding-top: 20px;
  }

  @media screen and (max-width: 999px) {
    padding-bottom: 96px;
  }

  .left {
    width: 90%;
    span {
      &.sub-head {
        color: #bdcfdd;
        font-size: 20px;
        font-weight: 400;
        line-height: 100px;
        text-transform: uppercase;
        letter-spacing: 4px;
        @media screen and (max-width: 768px) {
          line-height: 47px;
          text-align: center;
          width: 100%;
          display: inline-block;
        }
      }
    }

    h1 {
      color: #ffffff;
      font-size: 58px;
      font-weight: 600;
      line-height: 86px;
      letter-spacing: 4.25px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 7rem;
      @media screen and (max-width: 999px) {
        line-height: 69px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 1024px) {
        font-size: 55px;
        line-height: 56px;
        letter-spacing: 1.25px;
        margin-bottom: 30px;
        margin-left: 0px;
        text-align: center;
      }
    }

    p {
      color: #ffffff;
      font-size: 26px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: -0.26px;
      strong {
        font-weight: 700;
        letter-spacing: -0.26px;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .right {
    .video-holder {
      display: flex;
      border: solid 21px rgba(0, 0, 0, 0.19);
    }
  }

  .bottom-banner {
    background-color: #224463;
    opacity: 0.8;
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 0;
    @media screen and (max-width: 980px) {
      height: 120px;
    }
  }
  .bottom-banner-text {
    color: #ffffff;
    position: absolute;
    text-align: center;
    bottom: 0;
    font-size: 2rem;
    font-weight: bold;
    width: 100%;
    line-height: 15px;
    z-index: 2;
    left: 0;
    @media screen and (max-width: 980px) {
      line-height: 30px;
    }
  }

  &.estimate {
    text-shadow: 3px 1px 8px #404040;
    .right {
      @media screen and (max-width: 670px) {
        width: 100%;
      }
    }

    .left {
      h1 {
        font-size: 58px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -1.45px;
        @media screen and (max-width: 450px) {
          font-size: 38px;
          line-height: 50px;
        }
      }

      p {
        opacity: 0.8;
        color: #ffffff;
        font-size: 22px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 30px;
        @media screen and (max-width: 450px) {
          font-size: 21px;
          margin-bottom: 25px;
        }
      }
    }
    .contact-form {
      // margin-top: -50px;
      border-radius: 10px;
      background-color: #ffffff;
      width: 540px;
      max-width: 100%;
      h3 {
        text-align: center;
        color: #384660;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.75px;
        padding-top: 40px;
        padding-bottom: 21px;
      }
      .estimate-inputs {
        padding-left: 54px;
        padding-right: 54px;
        margin-bottom: 44px;
        @media screen and (max-width: 444px) {
          padding-left: 24px;
          padding-right: 24px;
        }
        .input-holder {
          position: relative;
          margin-bottom: 10px;
          opacity: 0.9;
          svg {
            position: absolute;
            top: 14px;
            left: 14px;
            height: 26px;
            z-index: 9;
            fill: #96d3ff;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: -0.6px;

            @media screen and (max-width: 670px) {
              top: 0px;
              left: 8px;
            }
          }
          input {
            padding-left: 68px;
            color: #384660;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: -0.5px;
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            border-bottom: solid 2px #e3e9f6;

            @media screen and (max-width: 670px) {
              font-size: 14px;
              padding-left: 58px;
            }
          }

          .error-message {
            display: none;
            color: #ef3a3a;
            text-align: right;
            width: 100%;
          }

          &.invalid {
            i {
              color: #ef3a3a;
            }

            input {
              border-bottom: solid 2px #ef3a3a;
            }

            .error-message {
              display: inline-block;
            }
          }
        }
      }

      .submit-btn {
        button,
        .estimate-form-submit {
          border-radius: 0 0 10px 10px;
          background-color: #1d3c5a;
          background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.16) 0%,
            rgba(255, 255, 255, 0.16) 100%
          );
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 4px;
          padding: 33px 30px;
          border: 0px;
        }

      }
      .submit-btn.hoverEvents input:hover{
        cursor: pointer;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.46) 0%, rgba(255, 255, 255, 0.26) 100%);
      }
      @media screen and (max-width: 670px) {
        width: 100%;
        h3 {
          font-size: 24px;
        }
      }
    }
    .contact-form-2 {
      background-color: rgba(0, 0, 0, 0.47);
      margin-top: 1rem;
      width: 400px;
      padding-bottom: 1px;
      margin-left: 7rem;
      border-radius: 4px;
      max-width: 100%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-left: 0px;
      }
      h3 {
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 700;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
      }
      .estimate-inputs {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px;
        @media screen and (max-width: 444px) {
          padding-left: 24px;
          padding-right: 24px;
        }
        .input-holder {
          position: relative;
          margin-bottom: 10px;

         input {
          background-color: #eee;
          &:hover{
            background-color: #fff;
          }
         }


          svg {
            position: absolute;
            top: 14px;
            left: 14px;
            height: 26px;
            z-index: 9;
            fill: #96d3ff;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: -0.6px;
          }
          input {
            padding-left: 10px;
            color: #384660;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: -0.5px;
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            border-bottom: solid 2px #e3e9f6;
          }

          .error-message {
            display: none;
            color: #ef3a3a;
            text-align: right;
            width: 100%;
          }

          &.invalid {
            i {
              color: #ef3a3a;
            }

            input {
              border-bottom: solid 2px #ef3a3a;
            }

            .error-message {
              display: inline-block;
            }
          }
        }
      }

      .submit-btn {
        button,
        .estimate-form-submit {
          width: 50%;
          background-color: #e30057; 
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 4px;
          padding: 10px 10px;
          border: 0px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
          &.red {
            background-color: #e30057;
          }
          &.orange {
            background-color: #ffa500;
          }
        }



        &.topForm input{

          &:hover {
            background-color: #ff0062;
            cursor: pointer;
          }
        }
      }
    }
  }
  &--home {
    @media screen and (min-width: 600px) {
      align-items: center;
      h1 {
        margin-left: 5rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        align-self: center;
      }
      span {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
      }
    }
    @media screen and (min-width: 1100px) {
      &.estimate {
        padding-top: 0px;
        .contact-form-2 {
          min-width: 400px;
          margin-top: -50px;
        }
      }
      height: 100%;
      .left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        width: 100%;
        margin-top: -80px;
        h1 {
          position: relative;
          top: -20px;
        }
      }
    }
    @media screen and (min-width: 1400px) {
      &.estimate {
        .contact-form-2 {
          min-width: 400px;
          margin-top: 0px;
        }
      }
      .left {
        padding-top: 0px;
        margin-top: 0px;
        flex-direction: row-reverse;
        justify-content: flex-end;
        h1 {
          align-self: flex-start;
          top: -55px;
          margin-left: 2rem;
        }
      }
    }
    @media screen and (min-width: 1700px) {
      .left h1 {
        margin-left: 10rem;
      }
    }
  }
}

.page-title {
  border-left: solid 5px #5bb4ff;
  padding-left: 42px;
  @media screen and (max-width: 500px) {
    padding-left: 18px;
  }

  h1 {
    color: #2e4659;
    font-size: 50px;
    font-weight: 800;
    line-height: 90px;
    letter-spacing: 1.6px;
    @media screen and (max-width: 500px) {
      font-size: 28px;
      line-height: 50px;
    }
  }
}

.home-services {
  .service {
    display: flex;
    justify-content: space-around;
    padding: 81px 0px;
    @media screen and (max-width: 1064px) {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 1064px) {
      padding: 20px 0px;
    }
    h2 {
      color: #2e4659;
      font-size: 26px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 1.6px;
      border-bottom: solid 2px #5fa9e7;
      display: inline-block;
      padding-bottom: 11px;
      padding-right: 30px;
      margin-top: 0px;
    }
    p {
      color: #5a6a76;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
    }

    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        display: inline-block;
        margin-right: 20px;
        a {
          color: #2e4659;
          font-size: 23px;
          font-weight: 400;
          line-height: 28px;
          text-decoration: none;
          border-bottom: solid 2px #9ccaf0;
          padding: 10px 0px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          svg {
            fill: #94c6ef;
            margin-left: 10px;
            height: 25px;
          }
        }
      }
    }

    &.service-image-left {
      .left {
        display: inline-block;
        width: 690px;
        @media screen and (max-width: 689px) {
          width: 100%;
        }
        img {
          width: 690px;
        }
      }
      .right {
        width: 416px;
        margin-left: 35px;
        @media screen and (max-width: 1064px) {
          width: auto;
          margin-top: 30px;
          margin-left: 50px;
        }
      }
    }

    &.service-image-right {
      flex-direction: row-reverse;
      @media screen and (max-width: 1064px) {
        flex-direction: column;
        align-items: center;
      }

      @media screen and (max-width: 500px) {
        padding-bottom: 50px;
      }
      .left {
        img {
          width: 690px;
        }
      }

      .right {
        width: 426px;
        margin-right: 35px;
        @media screen and (max-width: 768px) {
          width: auto;
          margin-left: 50px;
          margin-top: 30px;
          margin-right: 0px;
        }
      }
    }
  }

  .get-quote-btn {
    text-align: center;
  }
}

#recent-work {
  width: 100%;
  float: left;
  margin-bottom: 10px;
  @media screen and (max-width: 890px) {
    padding-top: 100px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 40px;
  }
  .recent-work-items {
    /* fluid 5 columns */
    .grid-sizer,
    .grid-item {
      width: 25%;
      background: #000;
      @media screen and (max-width: 1020px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }

    .grid-item {
      display: flex;
      // align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;
      height: 263px;
      background-size: cover;
      text-decoration: none;

      &.item-1 {
        height: 580px;
        @media screen and (max-width: 1020px) {
          height: 290px;
        }
        h3 {
          padding: 0px 30px 0px 83px;
          @media screen and (max-width: 445px) {
            padding: 0px 20px;
          }
        }

        h2 {
          padding: 30px 30px 70px 83px;
          @media screen and (max-width: 445px) {
            padding: 20px;
          }
        }
      }

      &.item-1,
      &.item-2,
      &.item-3,
      &.item-4,
      &.item-5 {
        width: 50%;
        @media screen and (max-width: 1020px) {
          width: 100%;
        }
      }

      &.item-2,
      &.item-3,
      &.item-4,
      &.item-5 {
        height: 290px;
        h3 {
          padding: 0px 30px 0px 64px;
          @media screen and (max-width: 445px) {
            padding: 0px 20px;
          }
        }

        h2 {
          padding: 30px 30px 58px 64px;
          @media screen and (max-width: 445px) {
            padding: 20px;
          }
        }
      }

      &:after {
        content: "";
        transition: all 0.5s ease;
        position: absolute;
        z-index: 9;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(14, 14, 14, 0.71) 100%
        );
      }

      &:hover {
        &:after {
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.44) 0%,
            rgb(14, 14, 14) 100%
          );
        }
      }

      h3 {
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 3.6px;
        position: relative;
        z-index: 99;
        margin: 0px;
      }

      h2 {
        color: #ffffff;
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 0.8px;
        position: relative;
        z-index: 99;
        margin: 0px;
      }
    }
  }

  .below-recent-work-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0px;
    @media screen and (max-width: 490px) {
      flex-direction: column;
      padding: 0px;
    }
    a {
      text-decoration: none;
      font-size: 32px;
      font-weight: 600;
      line-height: 90px;
      border-bottom: solid 3px;
      &.left-link {
        color: #2e4659;
      }

      &.right-link {
        color: #00aede;
      }
    }

    i {
      opacity: 0.7;
      color: #2e4659;
      font-size: 32px;
      @media screen and (max-width: 490px) {
        margin-top: 30px;
      }
    }
  }
}

#design-center-slider {
  padding: 0px !important;
  * {
    user-select: none;
    outline: none;
  }
  img {
    @media (max-width: 768px) {
      width: auto;
    }
  }
  .slick-track {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 3rem;

    @media (min-width: 300px) and (max-width: 450px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .slick-dots {
    padding: 0px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin-right: 10px;
      button {
        width: 12px;
        height: 12px;
        border: 0px;
        border-radius: 50%;
        background: #e6e6e6;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        color: transparent;
        &:before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background: #b4b4b4;
        }
      }
    }
  }
}

.blog {
  .container {
    max-width: 80%;
  }
  .btn {
    cursor: pointer;
    border: none;
    background: #00aede;
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 25px;
    &.red {
      background-color: #e30057;
    }
    &.orange {
      background-color: #ffa500;
    }
  }

  
  h2 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  span {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 2em;
    display: inline-block;
  }
  .blog-list {
    img {
      border: 2px solid lightblue;
    }
    li {
      padding-top: 3em;
      padding-bottom: 3em;
      text-align: left;
      border-bottom: 2px solid lightblue;
    }
    .btn.btn-primary {
      width: auto;
    }
  }
}

#our-customers {
  padding-bottom: 50px;
  padding-top: 150px;
  @media screen and (max-width: 1222px) {
    padding-top: 40px;
    padding-bottom: 0px;
  }
  h2 {
    color: #2e4659;
    font-size: 60px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
  }

  .testimonial-holder {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1222px) {
      flex-direction: column;
      align-items: center;
    }

    .image-holder {
      @media screen and (max-width: 1280px) {
        padding-right: 35px;
      }

      @media screen and (max-width: 1222px) {
        padding-right: 0px;
      }
      img {
        width: 400px;
      }
    }
  }

  .slick-slide {
    outline: none;
  }

  .testimonials {
    width: 658px;
    max-width: 100%;
    div {
      p {
        color: #2e4659;
        font-size: 30px;
        font-weight: 900;
        line-height: 46px;
        letter-spacing: 1px;
        @media screen and (max-width: 411px) {
          font-size: 20px;
          line-height: 35px;
        }
      }

      .customer {
        display: flex;
        align-items: center;
        .customer-image {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          background-size: cover;
          background-size: 130%;
          background-position: center;
        }

        h2 {
          color: #2e4659;
          font-size: 24px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 2.4px;
          margin: 0px;
        }

        .name-and-arrows {
          padding-left: 29px;
          .arrows {
            margin-top: 18px;
            button {
              width: 32px;
              height: 32px;
              background-color: #dadada;
              border: 0px;
              color: #2e4659;
              font-size: 20px;
              margin-right: 11px;
              outline: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#warranty {
  padding-bottom: 50px;
  @media screen and (max-width: 1222px) {
    padding-bottom: 40px;
  }
}

#footer {
  width: 100%;
  float: left;
  background-color: #2f2f2f;
  .top {
    > div {
      display: flex;
      justify-content: space-between;
      padding: 50px 0px;
      @media screen and (max-width: 1396px) {
        padding: 50px 30px;
        flex-wrap: wrap;
      }
    }
    h4 {
      color: #8499ab;
      font-size: 26px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.3px;
      margin-top: 40px;
      margin-bottom: 10px;
      @media (min-width: 300px) and (max-width: 450px) {
        font-size: 20px;
      }
    }

    .agm-menu {
      @media screen and (max-width: 1058px) {
        width: 50%;
      }
      @media screen and (max-width: 690px) {
        width: 50%;
        text-align: center;
      }
    }

    .company-menu {
      @media screen and (max-width: 1058px) {
        width: 50%;
      }
      @media screen and (max-width: 690px) {
        width: 50%;
        text-align: center;
      }
    }


    p {
      font-size: 16px;
      margin: 0px;
    }

    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style-type: none;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0px;
        }
        a {
          text-decoration: none;
          color: #fff;
          font-size: 17px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 1px;
          @media (min-width: 300px) and (max-width: 450px) {
            font-size: 12px;
          }
        }
      }
    }


  }

  .bottom {
    border-top: solid 2px rgba(36, 40, 43, 0.1);
    padding: 30px 0px;
    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 550px) {
        flex-direction: column;
      }
      p {
        margin: auto;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.8px;
      }
    }

    @media screen and (max-width: 690px) {
      padding: 30px 0px 100px;
    }
  }
  .floating-call-btn-mobile {
    display: none;
    @media screen and (max-width: 630px) {
      display: initial;
      position: relative;
      z-index: 10000;
    }

    a {
      color: #fff;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: fixed;
      bottom: 120px;
      right: 20px;
      padding: 10px 20px;
      background-color: rgb(70, 191, 238);
      background: linear-gradient(
        90deg,
        rgb(70, 191, 238) 0%,
        rgb(41, 220, 224) 100%
      );
      background-image: -ms-linear-gradient(
        90deg,
        rgb(70, 191, 238) 0%,
        rgb(41, 220, 224) 100%
      );
      box-shadow: 3px 3px 4px #3e3e3e;
      color: #fff;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      animation: bounce 4s ease infinite;

      &:before {
        font-family: "FontAwesome";
        content: "\f095";
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
  @keyframes bounce {
    0%,
    8%,
    12%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    90% {
      transform: translateY(-10px);
    }
  }
}

.hours-and-social {
  @media screen and (max-width: 1058px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 52px;
    border-top: solid 2px rgba(36, 40, 43, 0.1);
    margin-top: 50px;
  }

  @media screen and (max-width: 570px) {
    flex-direction: column;
    display: none;
  }
}

.hours-and-social {
  ul {
    &.social-menu {
      margin-top: 44px;
      display: flex;
      // align-items: center;
      justify-content: center;
      li {
        margin: 0px;
        margin-right: 26px;
        list-style: none;
        svg {
          height: 34px;
          // fill: #333;
        }
      }
    }
  }
}

.ft-logo {
  @media (min-width: 300px) and (max-width: 450px) {
    width: 20%;
  }
}

.social-menu-mobile {
  display: none;
  @media (min-width: 300px) and (max-width: 450px) {
    height: 70px;
    display: flex;
    background: #00aede;
    padding: 20px 30px;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 0;
    div {
      width: 20%;
    }
    svg {
      height: 30px;
      fill: #fff;
    }
  }
}

.design-centers-footer {
  display: none;
  @media (min-width: 300px) and (max-width: 450px) {
    height: 70px;
    display: flex;
    background-color: #2e4659;
    padding: 10px 10px;
    align-items: center;
    max-width: 100%;
    margin: 0;
    img {
      height: 25px;
      fill: #fff;
    }
    span {
      color: #fff;
    }
  }
}

.portfoliolist {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .portfolio {
    position: relative;
    // width: 23.5%;
    width: 300px;
    background-size: cover;
    background-position: center;
    margin: 0.6rem;
    @media screen and (max-width: 1199px) {
      width: 47%;
    }
    @media screen and (max-width: 650px) {
      width: 100%;
      margin: 0.2rem 0.3rem;
    }
    // height: 223px;
    &:after {
      content: "";
      position: absolute;
      z-index: 9;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      // background: rgb(0, 0, 0);
      // background: linear-gradient(
      //   to bottom,
      //   rgba(0, 0, 0, 0) 0%,
      //   rgba(14, 14, 14, 0.71) 100%
      // );
    }

    .portfolio-wrapper {
      position: relative;
      overflow: hidden;
      height: 223px;
    }

    .lbl {
      position: absolute;
      top: 0px;
      z-index: 99;
      width: 100%;
      height: 223px;
      a,
      .port-item {
        color: #fff;
        text-decoration: none;
        width: 100%;
        padding: 0px 20px;
        position: absolute;
        width: 100%;
        height: 223px;
        padding-bottom: 13px;
        align-items: flex-end;
        display: flex !important;
        cursor: pointer;
      }
    }
  }
}

// .filter-ul {
//   @media (min-width: 300px) and (max-width: 450px) {
//     display: none;
//   }
// }

#filters {
  padding: 0px;
  margin: 0px;
  flex-wrap: wrap;
  display: flex;
  padding: 20px 0px;
  li {
    list-style-type: none;
    color: #fff;
    width: auto;
    flex-grow: 1;
    padding: 8px;
    text-align: center;
    padding: 0px;
    cursor: pointer;

    span {
      padding: 20px 0px;
      font-size: 13px;
      background-color: #224463;
      width: 100%;
      height: 100%;
      display: inline-block;
      cursor: pointer;
      &.active {
        background-color: #cc6d00;
      }
    }
  }
}

#bottom-map {
  width: 100%;
  float: left;
}

// Free consultation
.free-consultation-fixed {
  position: fixed;
  bottom: 0px;
  right: 50px;
  background-color: #224463;
  box-shadow: 0 0 10px rgba(34, 68, 99, 0.5);
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding: 16px 30px;
  width: 400px;
  z-index: 99;
}

.custom-contact-form {
  input {
    &[type="text"],
    &[type="tel"],
    &[type="email"],
    &[type="number"] {
      border: solid 1px rgba(0, 0, 0, 0.14);
    }
  }
}

.free-consultation-fixed span {
  margin-left: 20px;
}

.free-consultation-fixed i.bottom-item-status {
  float: right;
  font-size: 25px;
  transition: all 0.5s ease;
}

.free-consultation-fixed.closed i.bottom-item-status {
  transform: rotate(180deg);
}

.free-consultation-fixed.closed {
  cursor: pointer;
}

.free-consultation-menu {
  position: fixed;
  width: 400px;
  height: 410px;
  background: #fff;
  z-index: 9999;
  bottom: 57px;
  right: 50px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.16);
  transition: all 1.5s ease;
  overflow: hidden;
}

.free-consultation-menu.closed {
  height: 0px;
}

.free-consultation-menu .close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
  color: #224463;
}

.free-consultation-menu.show-item {
  display: flex;
}

.free-consultation-menu .image-holder {
  flex-basis: 350px;
  height: 100%;
}

.free-consultation-menu .form-holder {
  flex-basis: 100%;
  padding: 10px 30px;
}

.free-consultation-menu .form-holder h3 {
  margin: 23px 0px;
  color: rgba(0, 0, 0, 0.81);
}

.lightbox-cover {
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.81);
  z-index: 9998;
  width: 100%;
  height: 100%;
  display: none;
}

.lightbox-cover.show-item {
  display: inline-block;
}

.free-consultation-menu .input-holder {
  margin-top: 15px;
}

.free-consultation-menu .input-holder:first-child {
  margin-top: 0px;
}

.success-holder {
  text-align: center;
}

.success-holder i {
  font-size: 40px;
}

.success-holder {
  display: none;
}

.success-holder.show-item {
  display: inline;
}

.hide-item {
  display: none !important;
}

.form-holder {
  input[type="submit"] {
    background: #00aede;
  }
}

.loading {
  color: #fff;
  text-align: center;
  font-size: 10px;
  padding: 8px;
  background: #00aede;
}

.page-template-page-thankyou .free-consultation-fixed,
.page-template-page-thankyou .free-consultation-menu {
  display: none !important;
}

@media screen and (max-width: 465px) {
  .free-consultation-menu,
  .free-consultation-fixed {
    width: 90%;
    right: 50%;
    margin-right: -45%;
  }

  .free-consultation-menu h3,
  .free-consultation-fixed h3 {
    font-size: 21px;
  }
}

.basement-renovation-services {
  margin-bottom: 20px;
  clear: both;
}
.basement-renovation-services ul li a {
  color: #000;
}
.basement-renovation-services .sub-list {
  width: 50%;
  float: left;
  margin-bottom: 20px;
}
.agm-basements-more {
  clear: both;
}
.text ul {
  clear: both;
  margin-bottom: 20px !important;
}
.agm-basements-more a {
  color: #f89635;
  padding: 20px;
  font: 400 18px "Lato", sans-serif;
}

.blog .blog-list {
  margin-top: 25px;
}
.blog-details {
  margin-top: 50px;
}
.basements .img-hldr {
  padding-top: 20px;
}
.basements .img-hldr p a {
  text-shadow: 2px 2px 4px #ee8a28;
}
.main-bnr-txtHldr p {
  font-size: 70px !important;
}
.main-bnr-txtHldr p.sml {
  font-size: 40px !important;
}

/*Block 3*/
.block-b.first {
  text-align: center;
  height: 190px;
  background-image: url(https://www.agmrenovations.com/wp-content/uploads/2016/11/blue_blur_background_211427.jpg);
  color: #fff;
  width: 30.66%;
  float: left;
  margin: 20px 2% 20px 0;
  display: block;
  min-width: 230px;
  position: initial;
}
.first.second {
  background-image: url(https://www.agmrenovations.com/wp-content/uploads/2016/11/blurbackgrounds9.jpg);
}
.first.third {
  background-image: url(https://www.agmrenovations.com/wp-content/uploads/2016/11/blurred-background-2.jpg);
}

.block-d {
  text-align: left;
  form {
    text-align: center;
  }
  .title-submit {
    margin: 10px auto;
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .paragraph {
    font-size: 1rem;
    text-align: left;
  }
  .dots {
    width: 58%;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
.bg-grey {
  background: #ebeced;
}
.bg-white {
  background: #fff;
}
.block-c {
  line-height: 18pt;
  .title-blue-border {
    border-bottom: 2px solid #6dc0e7;
    font-size: 20px;
  }
  .title-2 {
    font-size: 20px;
  }
}
.rounded {
  border-radius: 10px;
}
.border-shadow {
  box-shadow: 1px 1px 10px #bdbdbd;
}
.btn-custom {
  text-align: center;
  padding: 1rem !important;
  border-radius: 5px;
  color: white;
  display: inline-block;
  cursor: pointer;
  background: #00aede;
  &.mod {
    width: 50%;
    margin: auto;
  }
}
.careers h2 {
  color: #192e44;
  font-family: "arial", sans-serif;
  font-weight: 700;
  font-size: 30px;
}
.block-hover {
  background: rgba(28, 28, 28, 0.75);
  color: #fff;
  height: 100%;
  padding: 1.25rem 2.5rem;
  text-shadow: 1px 1px 1px rgba(28, 28, 28, 0.7);
  display: block;
  -webkit-justify-content: center;
}
.block-hover:hover {
  background: none;
}
/*End-block3*/

.main-heading {
  font-weight: 700;
  font-size: 48px;
  font-family: roboto;
}

.features {
  padding: 0 15px;
}

.features img {
  float: left;
  margin-right: 20px;
  margin-bottom: 102px !important;
  width: auto;
  height: auto;
}

.features h4 {
  font-family: roboto;
}

.features p {
  font-size: 16px;
  color: #333333;
  font-size: 16px;
}

.vc_btn3.vc_btn3-size-lg.vc_btn3-style-outline,
.vc_btn3.vc_btn3-size-lg.vc_btn3-style-outline-custom {
  padding: 15px 24px !important;
  font-family: roboto;
  letter-spacing: 1px;
  font-size: 20px;
}

.vc_btn3.vc_btn3-color-warning,
.vc_btn3.vc_btn3-color-warning.vc_btn3-style-flat {
  color: #fff;
  background-color: #f7a132;
  padding: 15px 24px !important;
  font-family: roboto;
  letter-spacing: 1px;
  font-size: 20px;
}

.wpb_image_grid .wpb_image_grid_ul img {
  padding: 5px;
}

.main-banner h1 {
  text-align: left;
  color: #fff;
  font: 400 58px "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.25);
  float: left;
  padding: 0 10px;
}

.main-banner h2 {
  text-align: left;
  color: #f89734;
  font: 400 50px "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  float: left;
  width: 100%;
  margin-bottom: 30px !important;
}

.form-cont {
  padding: 20px;
}

.main-form {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 5px 20px 10px;
}

input,
button,
select,
textarea {
  padding: 10px;
  color: #333333;
  font-family: Roboto;
  width: 100%;
  border: 1px solid black;
}

.main-form h2 {
  font-weight: 700;
}

input[type="submit"] {
  width: 100%;
  background-color: #f99419;
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 12px 0;
}

label {
  display: block;
}

.right-btn {
  padding-right: 50%;
}

.left-btn {
  padding-left: 50%;
}
s .wufoo input.text,
.wufoo textarea.textarea,
.wufoo select.select,
form li.complex div span.full input {
  margin-top: 0;
}

.home-banner {
  background: url(https://www.agmrenovations.com/wp-content/uploads/2017/08/Main-Banner-1.jpg)
    no-repeat;
  background-size: cover;
}

.vc_row {
  margin: 0 !important;
}

.wufoo label.desc,
.wufoo legend.desc {
  display: none;
}

.wufoo input.text,
.wufoo textarea.textarea,
.wufoo input.file,
.wufoo select.select {
  font-size: 15px;
  padding: 6px;
  background-color: #f9f9f9;
  box-shadow: none;
  border-top: 0;
  width: 96%;
}

.wufoo input.btTxt {
  font-size: 24px;
  padding: 10px;
  background-color: #f16632;
  color: #fff;
  border: 0;
  display: block;
  width: 100%;
  border-radius: 0;
  cursor: pointer;
}

.wufoo input.btTxt:hover {
  background-color: #dc4710;
}

.wufoo .info,
.wufoo .paging-context {
  border-bottom: 0;
  padding: 0;
}

.schedule-form {
  width: 50%;
  float: left;
  padding: 50px 40px 0;
}

#form1 li.buttons a[target="_blank"] {
  display: none !important;
}

.create-message {
  display: none !important;
}

.start-cta {
  display: none !important;
}

.confirm .create-message {
  display: none !important;
}

.confirm a.start-cta {
  display: none !important;
}

.confirm .logo-container {
  display: none !important;
}

.wufoo .info h2 {
  display: none;
}

.wd-row {
  margin-bottom: 0;
}

.post-700,
.post-1478,
.post-1713,
.post-1511 {
  margin-top: 13%;
}

.post-700 .entry-title,
.post-1478 .entry-title,
.post-1713 .entry-title,
.post-1511 .entry-title {
  display: none;
}

.financeit-btn {
  color: #fff;
  float: left;
  font-size: 16px;
  margin: 6px 13px 6px;
}

.financeit-btn:hover {
  color: #fff;
  text-decoration: none;
}

.financeit-btn span {
  padding-right: 12px;
}

.finance-text {
  float: left;
  color: #fff;
  font-size: 16px;
  padding-top: 12px;
}

.top-header .social {
  width: auto !important;
}

.main-bnr-txtHldr a:last-child {
  background: none !important;
}

.financeitpage-text {
  background: #f7f7f7;
  padding: 50px;
  margin-top: 7% !important;
}

.financeitpage-text p {
  font-size: 20px;
}
.careers .block-b:first-child {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.careers .block-c {
  .bg-grey,
  .bg-white {
    border-radius: 0 0 15px 0;
  }
}
@media only screen and (max-width: 767px) {
  .careers .block-c .row.p-3.text-center .col-12 {
    display: flex;
    flex-direction: column;
  }
  .careers {
    .coreValues {
      .submitResume {
        justify-content: center;
        display: flex;
      }
    }
  }
  .careers .block-c .row.p-3.text-center .col-12 small {
    font-size: 18px;
  }
  .hide-on-mob {
    display: none;
  }

  .header .Call-now p span {
    display: none;
  }

  .right-btn {
    padding-right: 0;
  }

  .left-btn {
    padding-left: 0;
  }

  .form-cont {
    padding: 0 !important;
  }

  .main-form h2 {
    font-size: 26px;
  }

  .ls-layers p {
    font-size: 14px;
  }

  .banner {
    height: 560px;
  }

  .fullscreen-img {
    display: block;
    margin: 0px;
    width: auto;
  }

  .caro {
    margin-top: 10px;
  }

  .main-bnr-txtHldr a:last-child {
    padding: 13px 0px;
  }

  .main-bnr-txtHldr a:last-child img {
    width: 100%;
  }

  .financeitpage-text {
    padding: 50px 20px;
  }

  .carousel-inner {
    margin-top: 42% !important;
  }

  .main-header .hidden-xs:nth-child(1) {
    display: block !important;
  }

  .top-header .col-md-6:first-child,
  .top-header .social {
    display: none !important;
  }

  .finance-text {
    float: left;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
  }

  .financeit-btn {
    color: #fff;
    float: left;
    margin: 4px 0 4px 6px;
    text-align: center;
  }

  .financeit-btn img {
    width: 85%;
  }
}

@media only screen and (max-width: 480px) {
  .main-bnr-txtHldr p {
    font-size: 48px !important;
  }
  .main-bnr-txtHldr p.sml {
    font-size: 31px !important;
  }
  .estimation .estimate .image img {
    height: 600px !important;
  }
  form {
    margin-top: -620px;
  }

  .estimation .estimate form input[type="submit"] {
    padding: 13px 27px;
    font-size: 21px;
    float: left;
  }

  form {
    margin-top: 30px;
  }

  .estimation .estimate .image img {
    display: none;
  }

  .wufoo input.text,
  .wufoo textarea.textarea,
  .wufoo input.file,
  .wufoo select.select {
    width: 95%;
  }
}

.bottom-logos {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .bild-logo {
    width: 100px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  max-height: 460px;
  max-width: 690px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.vimeo-wrapper {
  position: relative;
  // max-height: 630px;
  overflow: hidden;
  margin-bottom: 60px;
  margin-top: 40px;
  .video-cover {
  }
  video {
    height: 800px;
    object-fit: fill;
  }
  video.ottawa {
    height: 1000px;
    object-fit: fill;
  }
}

.text-white {
  color: #fff;
}

.h-100 {
  height: 100% !important;
}

.hidden {
  display: none !important;
}

.form-processing {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(89, 198, 232, 0.86);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.testi-rht {
  h2 {
    margin-top: 0px;
  }
}

.hidden {
  display: none !important;
}

.wp-block-columns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-8wgz7g {
  height: 31px !important;
}

.heart-icon {
  svg {
    height: 17px;
  }
}

.right-arrow-icon {
  svg {
    fill: #fff;
    height: 20px;
  }
}

a {
  text-decoration: none;
}

.warranty-images {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emergency-banner {
  width: 100%;
  position: relative;
  margin: 0;
  background-color: #ffffff;
  @media (min-width: 300px) and (max-width: 450px) {
    padding: 0 !important;
  }
  .left-text p {
    color: black;
  }
  .right-content {
    img {
      height: 11.5rem;
      width: 100%;
      @media (min-width: 300px) and (max-width: 450px) {
        height: 5rem;
      }
    }
    p {
      color: black;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.5rem;
      margin-top: 0;
      @media (min-width: 300px) and (max-width: 450px) {
        font-size: 1rem;
        line-height: 1;
      }
    }
    .mobile-emergency-grid div {
      @media (min-width: 300px) and (max-width: 450px) {
        width: 50%;
      }
    }
  }
}

.emergency-modal {
  width: 60%;
  margin: auto;
  background-color: #2e4659;
  opacity: 0.9;
  border: none;
  border-radius: 10px;
  padding: 2rem;
  margin-top: 7.5rem;
  outline: none;
  position: relative;
  @media (min-width: 300px) and (max-width: 450px) {
    width: 80%;
    margin-top: 7rem;
    height: 30rem;
    padding: 1rem;
  }
  .x-button {
    color: #fff;
    font-size: 1rem;
    font-weight: 100;
    position: absolute;
    cursor: pointer;
    right: 1rem;
    top: 0;
    svg {
      font-size: 2rem;
      @media (min-width: 300px) and (max-width: 450px) {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        margin-left: 1rem;
      }
    }
  }
  p {
    color: white;
    font-size: 1.3rem;
    @media (min-width: 300px) and (max-width: 450px) {
      font-size: 1rem;
    }
  }
  ol {
    padding-left: 1.3rem;
    li {
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 1rem;
    }
  }
}
.visualizerFrame {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  margin-top: 62px;
}
.visualizerFrame iframe {
  width: 100%;
  height: 40vw;
}
.warrantyDisclaimer {
  display: flex;
  justify-content: center;
  padding: 0 20px;
}
.faqScroll {
  cursor: pointer;
  color: #1986f9;
}
.faqScroll:hover,
.blue.link:hover {
  text-decoration: underline;
  color: blue;
}
.bold-red,
.required {
  color: red;
}
.basementBanner {
  background-image: url("../Images/thumbnail_AGM-Banner-Image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: 50px;
  .inner-basement-content {
    padding: 30px;
    padding-top: 10px;
    @media (max-width: 500px) {
      padding: 0;
    }
  }
  #formLogo {
    width: 300px;
    margin-top: 50px;
    margin-left: 50px;
    @media (max-width: 769px) {
      width: 150px;
      margin-top: 30px;
      margin-left: 30px;
    }
  }
}
.inner-basement-content {
  display: flex;
  min-height: 329px;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.basementTitle {
  font-size: 40px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 5px;
  @media (max-width: 769px) {
    line-height: unset;
  }
  @media (max-width: 500px) {
    font-size: 30px;
  }
}
.basementDescription {
  color: #fff;
}
@media (max-width: 769px) {
  #leftBasementFrom {
    .MuiGrid-grid-xs-true {
      padding-right: 0;
    }
  }
}
#basementForm {
  padding: 0 60px;
  .houseInfo {
    label {
      margin-bottom: 15px;
      margin-top: 20px;
    }
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
  #basementUpload {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 5px;
    .MuiTypography-root {
      word-break: break-all;
    }
  }
  #basementUpload:hover {
    border: 1px solid #000;
  }
  #basementUpload {
    .MuiButton-contained {
      background-color: #43c4e4;
      color: white;
    }
  }
  #leftBasementFrom {
    .MuiGrid-grid-xs-true {
      padding-right: 20px;
    }
  }
  #ageHome {
    .MuiFormControl-root {
      width: 95%;
      @media screen and (max-width: 599px) {
        width: 100%;
      }
    }
  }
  .required {
    color: red;
  }
}
.thankyouBasement {
  margin-top: 200px;
  text-align: center;
}
.title-h2 {
  font-size: 28px;
}
.blue {
  color: #00b1e3;
}
#submit_resume {
  input[type="submit"] {
    background: linear-gradient(90deg, #46bfee, #29dce0);
  }
  input,
  textarea {
    border: 1px solid #c3c3c3;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    box-shadow: 1px 1px 2px #e6e6e6;
  }
  label {
    color: #999;
    cursor: pointer;
    padding: 10px 0px;
    border: 1px solid #c3c3c3;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #00000059;
  }
  input[type="file"] {
    display: none;
  }
  ::-webkit-input-placeholder,
  :-moz-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    text-align: center;
  }
}
.para-footer {
  font-size: 24px;
  color: black;
  font-weight: 600;
}

.geolocation-tab {
  position: fixed;
  bottom: 0px;
  padding: 12px;
  padding-bottom: 8px;
  left: 40px;
  z-index: 10000;
  background-color: #1d3c5a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 0.25s linear;
  cursor: pointer;
  &:hover {
    background-color: rgb(56, 56, 56);
    padding: 15px;
    padding-bottom: 12px;
  }
  span {
    font-size: 16px;
    color: white;
    padding: 5px;
    display: inline-block;
  }
  img {
    width: 24px;
    height: 24px;
    display: inline-block;
    float: left;
    margin-right: 5px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    left: 0px;
    border-radius: 0px;
    bottom: auto;
    top: 0px;
  }
}
.flexBox {
  ul.regionList {
    display: flex;
    font-size: 16px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    text-align: center;
    display: none;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    li {
      padding: 20px;
      cursor: pointer;
      border: 1px solid lightblue;
      flex: 1 1;
      display: inline;
      margin: 10px;
      text-align: center;
      border-radius: 5px;
      &.active {
        background: #00b1e3;
        color: white;
      }
    }
  }
}
.red {
  color: red;
}
.modal {
  .modal-header {
    height: 40px;
    margin-bottom: 10px;
    text-align: left;
    @media screen and (max-width: 676px) {
      text-align: center;
      margin-bottom: 0px;
    }
  }
  h2 {
    padding: 0px;
    margin: 0px;
    text-align: left;
    font-size: 2rem;
    line-height: 2rem;
    @media screen and (max-width: 676px) {
      font-size: 1.5rem;
      margin: 14px 0px 0px;
    }
  }
  .model-img {
    max-width: 32px;
    @media screen and (max-width: 676px) {
      width: 32px;
    }
  }
  .d-flex {
    margin-top: 20px;
  }
  .postal_input {
    label {
      padding: 0px;
      line-height: 46px;
    }
    input {
      flex: 1 1;
      margin-left: 20px;
      border: 2px solid gray;
      border-radius: 4px;
      padding: 0px 2rem;
    }
    a {
      cursor: pointer;
      padding: 10px;
      margin-left: 10px;
    }
    @media screen and (max-width: 676px) {
      input,
      a,
      label {
        text-align: center;
        margin-left: 0px;
      }
    }
  }
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%;
}
.d-flex .content {
  width: 100%;
}
.postal_input {
  label {
    padding: 0px;
    line-height: 34px;
  }
  input {
    flex: 1;
    margin-left: 20px;
    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }
  a {
    cursor: pointer;
    padding: 10px;
    margin-left: 10px;
    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
.contactContainerForm {
  @media screen and (max-width: 1024px) {
    max-width: 800px;
  }
  @media screen and (max-width: 768px) {
    max-width: 600px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 50px;
  }
  max-width: 800px;
  padding: 40px;
  margin: auto;
  background-color: #0000004a;
  #contactForm {
    .contact-inputs {
      .input-holder {
        input {
          padding-left: 10px;
          color: #384660;
          font-size: 20px;
          font-weight: 400;
          letter-spacing: -0.5px;
          margin-bottom: 10px;
          border: 1px solid #000;
        }
      }
      textarea {
        font-size: 20px;
      }
    }
    .submit-btn {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    .submit {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      background-color: #e30057;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 4px;
      padding: 10px;
      border: 0;
      box-shadow: 0 4px 4px 0 #00000080;
      width: 50%;
    }
    .submit:hover:before {
      transform: scaleX(1);
    }
    .submit:hover {
      background: #ff2e7e;
      transition-property: transform;
      transition-duration: 1s;
      transition-timing-function: ease-out;
    }
  }
}
.stationSlider {
  .slick-track .slick-slide.slick-current + .slick-active,
  .slick-track .slick-slide {
    transform: scale(0.85) !important;
  }
  margin-top: -85px;
  @media screen and (max-width: 450px) {
    margin-top: 100px !important;
  }
  .slick-prev:before {
    content: "\2190";
    color: #00aede;
  }
  .slick-next:before {
    content: "\2192";
    color: #00aede;
  }
  .stationImg {
    width: 100% !important;
    height: 150px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      max-width: 100%;
      max-height: 100%;
    }
  }
  .stationImg.toronto {
    img {
      max-width: 100px !important;
    }
  }
  .stationImg.cp {
    img {
      max-width: 100px !important;
    }
  }
  .stationImg.stationNews {
    img {
      max-width: 200px !important;
      max-height: 150px !important;
    }
  }
  .stationImg.stationRadio,
  .stationImg.kiss {
    img {
      max-height: 150px !important;
    }
  }
  .stationImg.chch {
    img {
      max-height: 100px !important;
    }
  }
  .stationImg.hamilton {
    img {
      max-height: 100px !important;
    }
  }
  .stationImg.Qstation,
  .stationImg.newsTalk,
  .stationImg.cfra {
    img {
      max-height: 200px !important;
    }
  }
}

#apexchat_prechat_invitation_wrapper {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
