.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  @media only screen and (max-width: 876px) {
    padding: 30px 10px 80px 10px;
    min-height: 100vh;
  }
  .modalCard {
    background: white;
    position: relative;
    padding: 25px;
    border: 1px solid lightskyblue;
    border-radius: 5px;
    @media only screen and (max-width: 876px) {
      width: 100%;
      padding: 8px;
    }
  }
}
