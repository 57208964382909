#vote-options {
  padding-top: 132px;
  @media screen and (max-width: 1127px) {
    padding-top: 60px;
  }
  .options {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1127px) {
      flex-direction: column;
    }
    .single-option {
      display: flex;
      width: 540px;
      max-width: 100%;
      min-height: 312px;
      box-shadow: 0 6px 57px rgba(61, 92, 124, 0.17);
      border-radius: 10px;
      background-color: #ffffff;
      padding: 50px 40px;
      @media screen and (max-width: 550px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
      }

      &:first-child {
        margin-right: 30px;
        @media screen and (max-width: 1127px) {
          margin-right: 0px;
          margin-bottom: 15px;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 550px) {
          align-items: center;
        }
      }

      .icon {
        width: 117px;
        height: 117px;
        border-radius: 50%;
        background-color: #5b7098;
        background-image: linear-gradient(-30deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        @media screen and (max-width: 550px) {
          margin-right: 0px;
        }
        svg {
          height: 38px;
          fill: #fff;
        }
      }
      h3 {
        color: #3c507c;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 1.8px;
        margin-bottom: 15px;
      }

      p {
        opacity: 0.6;
        color: #373e4c;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
      }

      a {
        border-radius: 10px;
        background-color: #f58713;
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 1.8px;
        padding: 15px 42px;
        margin-top: auto;
        /* float: left; */
        width: fit-content;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

#below-options {
  display: flex;
  justify-content: center;
  padding-top: 61px;
  margin-bottom: 140px;
  p {
    width: 501px;
    opacity: 0.8;
    color: #5b7098;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    text-align: center;
    display: inline-block;
  }
}

