#chatBox {
  position: fixed;
  bottom: -19px;
  height: 81px;
  width: 386px;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 4px 10px);
  right: 210px;
  z-index: 200;
}

#chatBox.mobile {
  bottom: 215px;
  filter: none;
  right: 2px;
  height: 85px;
  width: 90px;
  z-index: 200;
}

#chatBox.open {
  height: 485px;
}
#chatBox.mobile.open {
  width: 100%;
  z-index: 1000000;
  right: 0px;
  bottom: -16px;
}
