.wrapperRealEstate {
  display: flex;
  .sideBar {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 30%;
    max-width: 250px;
    margin-top: 64px;

    @media only screen and (max-width: 650px) {
      margin-left: 8px;
      width: 40%;
    }
    .filter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 30px;

      .scrollContainer {
        display: flex;
        flex-direction: column;
        height: 200px;
        overflow-y: scroll;
        width: 100%;
      }
      .scrollContainer::-webkit-scrollbar {
        width: 8px;
        @media only screen and (max-width: 650px) {
          width: 5px;
        }
      }
      .scrollContainer::-webkit-scrollbar-track {
        background: white; /* цвет зоны отслеживания */
        height: 20px;
      }
      .scrollContainer::-webkit-scrollbar-thumb {
        background-color: white;
        height: 20px;
        border-radius: 20px;
        border: 1px solid lightskyblue; /* отступ вокруг бегунка */
      }
      h3 {
        font-weight: 600;
        font-size: 21px;
        color: #153251;
        @media only screen and (max-width: 650px) {
          font-size: medium;
        }
      }
      button {
        width: 100px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 1;
    margin-top: 10px;

    h2 {
      font-weight: 600;
      text-shadow: none;
      color: #153251;
      // font-size: medium;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      background-color: white;
      width: 100%;
      padding: 30px;
      @media only screen and (max-width: 650px) {
        padding: 8px;
      }
      .person {
        margin-bottom: 30px;
        width: 220px;
        padding: 10px;
        border: 3px solid white;
        @media only screen and (max-width: 650px) {
          width: 120px;
        }
        img {
          border: 3px solid black;
          border-radius: 10px;
        }
        h4 {
          line-height: 20px;
          @media only screen and (max-width: 650px) {
            font-size: small;
          }
        }
        p {
          color: #74a2a1;
          font-weight: 600;
          @media only screen and (max-width: 650px) {
            font-size: small;
          }
        }
      }
      .person:hover {
        border: 3px solid lightskyblue;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 10px;
      }
    }
  }
}
