.agentWrapper {
  h2 {
    text-shadow: none;
    font-weight: 600;
    margin-bottom: 20px;
    @media only screen and (max-width: 440px) {
      font-size: medium;
    }
  }
  .closeBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 28px;
    height: 28px;
    @media only screen and (max-width: 440px) {
      top: 8px;
      right: 8px;
      width: 12px;
      height: 12px;
    }
  }
  .closeBtn:hover {
    filter: invert(73%) sepia(82%) saturate(1000%) hue-rotate(158deg)
      brightness(120%) contrast(89%);
    cursor: pointer;
  }
  .wrapper {
    display: flex;
    @media only screen and (max-width: 876px) {
      flex-direction: column;
    }
    .personContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 400px;
      @media only screen and (max-width: 876px) {
        width: 95%;
        align-items: center;
      }
      @media only screen and (max-width: 440px) {
        font-size: 12px;
        line-height: 20px;
      }
      img {
        margin-bottom: 30px;
      }
      .propertie {
        display: flex;

        width: 100%;
        p:first-child {
          font-weight: 600;
          color: lightskyblue;
          width: 50%;
        }
        p:last-child {
          font-weight: 500;
        }
      }
    }
    .infoContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
      width: 400px;
      @media only screen and (max-width: 876px) {
        width: 95%;
        margin-top: 0;
      }
      @media only screen and (max-width: 440px) {
        font-size: 12px;
        line-height: 15px;
      }
      .propertie {
        display: flex;

        width: 100%;

        p:first-child {
          font-weight: 600;
          color: lightskyblue;
          width: 50%;
        }
        p:last-child {
          font-weight: 500;
        }
      }
    }
  }
}
