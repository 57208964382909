.topvideo {
  display: block;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  height: 100%;
  margin-top: 40px;

}

.vis_quoteButton {
    position: absolute;
    right: 0;
    background: #e61b68;
    text-align: center;
    padding: 14px 20px;
    font-style: normal;
    font-size: 15px;
    color: white;
    font-weight: 700;
    background-color: transparent;
    min-width: 160px;
    margin: 20px;
    margin-top: 0;
    margin-right: 40px;
    max-width: 300px;
    text-transform: uppercase;
    background-color: #00AEDE;
    letter-spacing: .04em;
    z-index: 100;
    cursor: pointer;
}


.topvideoButton {
    position: absolute;
    width: 30px;
    border-radius: 50%;
    left: calc(50% - 36px);
    top: calc(50% - 10px);
    padding: 0;
    color: #fff;
    transform: scale(2.5);
    filter: drop-shadow(2px 4px 6px black);

    &:hover{
      color: #00ccff !important;
    }
}

.arrow {
  position: absolute;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
}
.listCont{
  li{
    margin-bottom: 20px;
    display: flex;
  }
  b{
    width: 100px;
  }
  span{
    width: 100%;
  }
}
.nextArrow {
  top: 45%;
  right: 0;
}

.prevArrow {
  top: 45%;
  left: 0;
}

.recent_content {
  position: absolute;
  bottom: 70px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: #ffffff;
}
.recent_sub_content {
  position: absolute;

  bottom: 40px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  &:hover {
    border-bottom: 2px white solid;
  }
}

.heading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 77px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #2c4758;
  margin: 40px auto;
  display: block;
  width: 100%;
  text-align: center;
}

.moreProject {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 74px;
  margin-bottom: 138px;
  .moreButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #00aede;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    width: 337px;
    height: 83px;
    cursor: pointer;
    color: white;
  }
}


@media screen and (max-width: 720px) {
  .vis_quoteButton.open {
    margin-top: -50px;
    margin-right: 0;
  }
}

.promoStamp{
  width: 320px;
  top: 139px;
  background-color: rgba(17, 17, 17, 0.6705882353);
  background-image: linear-gradient(180deg, #1a579c, transparent);
  border-radius: 3px;
  position: absolute;
  color: #fff !important;
  z-index: 2;
  right: 16px;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  font-weight: 400;
  text-align: center;
  padding: 20px 10px;

  .closeBtn {
    display: none;
    width: 30px;
    padding: 2px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: -6px;
    top: -10px;
    color: #1863a5;
    background-color: #fff;
    cursor: pointer;
  }


  .topPar span{
    text-transform: uppercase;
    font-weight: 700;
    color: #fff !important;
  }

  .middleOffer {
    text-align: left;
    padding: 15px 20px;
    padding-top: 0px;
    text-transform: uppercase;
    color: #e4e4e4;
    line-height: 20px;
    font-size: 0.9em;
    font-weight: 300;

    &.mobile{
      text-align: center;
      margin: 10px 0 10px 0;
      padding: 10px 20px;
      display: none;
    }

    &.image::after{
      content: '';
      width: 40px;
      height: 50px;
      background-image: url("../Assets/Images/plus.png");
      position: absolute;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-top: -19px;
      margin-left: -243px;
    }
    
    // &.image.desk::after{
    //   margin-left: -167px;
    // }

    &.image.extra.desk::after{
      margin-left: -167px;
      margin-top: -38px;
    }
  }

  .button{
    background: #da8e03;
    border: none;
    border-radius: 4px;
    padding: 10px;
    line-height: 30px;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    cursor: pointer;
    margin: 10px;
    font-size: 0.9em;
    box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.3), 2px 2px 2px rgba(0, 0, 0, 0.1);

    &:hover{
      // background: linear-gradient(#12a4e3, #19569c);
      background: #FFA500;
      box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.4), 2px 2px 2px rgba(0, 0, 0, 0.25);
    }

    a {
      font-weight: 700;
      color: #fff;
    }
  }

  .endPart {
    font-size: 0.8em;
  }

  @media only screen and (max-width: 1380px) {
    top: 460px;
  }

  @media only screen and (max-width: 1024px) {
    width: calc(100% - 20px);
    height: auto;
    margin: 0;
    margin-left: 10px;
    left: 0;
    background-image: linear-gradient(180deg, #222125, #1a579c);
    top: auto;
    bottom: -348px;
    border-radius: 0px;

    .middleOffer {
      &.desk{
        display: none;
      }
      &.mobile{
        display: block;
      }

      &.image::after{
        margin-top: -17px;
        margin-left: -249px;
      }
    }

    .closeBtn {
      display: block;
    }

    .button{
      margin: 0 auto;
      max-width: 300px;
      margin-bottom: 16px;
    }
  }
  @media only screen and (max-width: 684px) {
    bottom: -407px;
    top: auto;
  }
  @media only screen and (max-width: 630px) {
    bottom: -217px;

    font-size: 0.9em;
    background-image: linear-gradient(180deg, #1a579c, transparent);
  
    .middleOffer.mobile{
      margin: 10px 0 10px 0;
      font-weight: 300;
    }

    .middleOffer.image::after{
        margin-top: -19px;
        margin-left: -227px;
    }

    .middleOffer.image.extra.mobile::after{
      margin-left: -287px;
    }
  }

  @media only screen and (max-width: 440px) {
    top: 492px;
    bottom: auto;
    margin-left: 10px;
  }

  // @media only screen and (max-width: 380px) {
  //   top: auto;
  //   bottom: -54px;
  //   margin-left: 10px;
  // }
}

.promoStampOffer{
  max-width: 800px;
  margin: 0 auto;
  top: 50px;
  background-color: rgba(17, 17, 17, 0.6705882353);
  border-radius: 3px;
  position: relative;
  color: #fff !important;
  z-index: 2;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  font-weight: 400;
  text-align: center;
  padding: 20px 10px;

  width: 100%;
  height: auto;
  left: 0;
  background-image: linear-gradient(180deg, #1a579c, #353535);

  border-radius: 0px;

  .closeBtn {
    display: none;
    width: 30px;
    padding: 2px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: -6px;
    top: -10px;
    color: #1863a5;
    background-color: #fff;
    cursor: pointer;
  }


  .topPar span{
    text-transform: uppercase;
    font-weight: 700;
    color: #fff !important;
  }

  .middleOffer {
    text-align: left;
    padding: 15px 20px;
    padding-top: 20px;
    text-transform: uppercase;
    font-size: 0.8em;
    line-height: 20px;
    color: #e7e7e7;
    font-weight: 400;

    &.mobile{
      text-align: center;
      margin: 10px 0 10px 0;
      padding: 10px 20px;
      display: none;
    }

    & strong{
      color: #fff;
      font-weight: 700;
    }

    &.image::after{
      content: '';
      width: 40px;
      height: 50px;
      background-image: url("../Assets/Images/plus.png");
      position: absolute;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-top: -18px;
      margin-left: -228px;
    }
    &.image.extra::after{
      margin-left: -288px;
    }
  }

  .button{
    background: linear-gradient(#0d8bc1, #19569c);
    border: none;
    border-radius: 4px;
    padding: 10px;
    line-height: 30px;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    cursor: pointer;
    margin: 10px;
    font-size: 0.9em;
    box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.3), 2px 2px 2px rgba(0, 0, 0, 0.1);

    &:hover{
      background: linear-gradient(#12a4e3, #19569c);
      box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.4), 2px 2px 2px rgba(0, 0, 0, 0.25);
    }

    a {
      font-weight: 700;
      color: #fff;
    }
  }

  .endPart {
    font-size: 0.8em;
    line-height: 16px;
    margin-top: 20px;
    color: #c4c4c4 !important;
  }

  @media only screen and (max-width: 1024px) {
    .middleOffer {
      &.image::after{
        margin-top: -18px;
        margin-left: -223px;
      }
    }
  }
  @media only screen and (max-width: 980px) {  
    width: calc(100% - 40px);
    top: 0px;
  }

  @media only screen and (max-width: 540px) {  
    top: -30px;
  }

    .middleOffer {
      &.desk{
        display: none;
      }
      &.mobile{
        display: block;
      }

    }

    .closeBtn {
      display: block;
    }

    .button{
      margin: 0 auto;
      max-width: 300px;
      margin-bottom: 16px;
    }

  // @media only screen and (max-width: 540px) {
  //   top: 520px;
  //   font-size: 0.9em;

  //   .middleOffer.mobile{
  //     margin: 10px 0 10px 0;
  //   }
  // }

  // @media only screen and (max-width: 440px) {
  //   top: 480px;
  // }
}


@media only screen and (max-width: 1065px) {
  .estimate-header-contain.home .banner-content.estimate {
    padding-bottom: 400px;
  }
  #home-banner.estimate-header-contain {
    background-size: cover;
  }
}